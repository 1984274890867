(function(){
     'use strict';

     angular
        .module('app')
        .factory('ProjectService', ProjectService);

    ProjectService.$inject = ['$http'];

    function ProjectService($http,$q,$window,$location){

        var project= {
           getProjects:getProjects
        };

 		function getProjects(cat_id)
        {
            var config = {headers : {}}

            return $http.get('/ajax/projects', {   
                params : {
                    categoria_id : cat_id
                }
            }, config)
            .then(function(data){
                return data.data;
            },function(data){
                return data;
            });
        };

        return project;
    }

})();