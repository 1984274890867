$.fn.serializeObject = function()
{
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

function autoValidateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function autoValidateDMY(param,value) {
    var date = value.split(param);
    var d = parseInt(date[0], 10),
        m = parseInt(date[1], 10),
        y = parseInt(date[2], 10);
    var res = new Date(y, m - 1, d);
    if(res=="Invalid Date"){
    	return false;
    }else{
    	return true;
    }
}

function autoValidateYMD(param,value) {
    var date = value.split(param);
    var d = parseInt(date[0], 10),
        m = parseInt(date[1], 10),
        y = parseInt(date[2], 10);
    var res = new Date(y, m - 1, d);
    if(res=="Invalid Date"){
    	return false;
    }else{
    	return true;
    }
}

function comprobarBloqueFormulario(cont_id){
	$('.form_'+cont_id+' .msgOK,.form_'+cont_id+' .msgKO').hide();
	$('.form_'+cont_id+' .has-error').removeClass("has-error");
	var resultado = true;
	var labels = [];
	$('.form_'+cont_id+' span.errorText').html("");
	$('.form_'+cont_id+' .alertR').hide();
	$('.form_'+cont_id+' input.obli,.form_'+cont_id+' textarea.obli').each(function(){
		if($(this).is(":visible")){
			var valor = $(this).val();
			if(valor.trim()==""){
				resultado = false;
				$(this).closest(".form-group").addClass("has-error");
				labels.push($(this).closest(".form-group").find(".control-label").text()+" "+$('.form_'+cont_id).attr("texto-obligatorio"));
				$(this).closest(".form-group").find(".errorText").text($('.form_'+cont_id).attr("texto-obligatorio"));
			}
		}
	})
	$('.form_'+cont_id+' select.obli').each(function(){
		if($(this).is(":visible") || $('#'+$(this).attr("id")+"-button").is(":visible") || $('#'+$(this).attr("id")+"SelectBoxItContainer").is(":visible")){
			var valor = $(this).val();
			if(valor.trim()==""){
				resultado = false;
				$(this).closest(".form-group").addClass("has-error");
				labels.push($(this).closest(".form-group").find(".control-label").text()+" "+$('.form_'+cont_id).attr("texto-obligatorio"));
				$(this).closest(".form-group").find(".errorText").text($('.form_'+cont_id).attr("texto-obligatorio"));
			}
		}
	})
	if($('.form_'+cont_id+' .obliCheck').length>0){
		var selected = [];
		if($(this).is(":visible")){
			$('.form_'+cont_id+' .obliCheck').each(function() {
			    //selected.push($(this).val());
			    var seleccionado = $(this).closest(".listChecks").find("input[type='checkbox']:checked").length;
			    if(seleccionado==0){
					resultado = false;
					$(this).closest(".form-group").addClass("has-error");
					labels.push($(this).closest(".form-group").find(".control-label").text()+" "+$('.form_'+cont_id).attr("texto-obligatorio"));
					$(this).closest(".form-group").find(".errorText").text($('.form_'+cont_id).attr("texto-obligatorio"));
			    }
			});
		}
	}
	$('.form_'+cont_id+' .obliCheckOne').each(function(){
		var obliCheckInvisible = $(this).closest(".checkbox").find(".check");
		if($(this).is(":visible") || obliCheckInvisible.is(":visible")){
			var seleccionado = $(this).closest(".checkbox").find("input[type='checkbox']:checked").length;
			if(seleccionado==0){
				resultado = false;
				$(this).closest(".checkbox").addClass("has-error");
				labels.push($(this).closest(".checkbox").find(".control-label").text()+" "+$('.form_'+cont_id).attr("texto-obligatorio"));
				$(this).closest(".checkbox").find(".errorText").text($('.form_'+cont_id).attr("texto-obligatorio"));
			}
		}
	})
			
	if($('.form_'+cont_id+' .obliRadio').length>0){
		var selected = [];
		$('.form_'+cont_id+' .obliRadio').each(function() {
			if($(this).is(":visible")){
			    //selected.push($(this).val());
			    var seleccionado = $(this).closest(".listChecks").find("input[type='radio']:checked").length;
			    if(seleccionado==0){
					resultado = false;
					$(this).closest(".form-group").addClass("has-error");
					labels.push($(this).closest(".form-group").find(".control-label").text()+" "+$('.form_'+cont_id).attr("texto-obligatorio"));
					$(this).closest(".form-group").find(".errorText").text($('.form_'+cont_id).attr("texto-obligatorio"));
			    }
			}
		});
	}
	
	if(resultado){
		$('.form_'+cont_id+' .emailFormularioF').each(function(){
			var valorEm = $(this).val();
			if($(this).is(":visible") && ($(this).hasClass(".obli") || valorEm.trim() != "")){
				if(!autoValidateEmail($(this).val())){
					resultado = false;
					$(this).closest(".form-group").addClass("has-error");
					labels.push($(this).closest(".form-group").find(".control-label").text()+" "+$('.form_'+cont_id).attr("texto-error"));
					$(this).closest(".form-group").find(".errorText").text($('.form_'+cont_id).attr("texto-obligatorio"));
				}
			}
		})
		$('.form_'+cont_id+' .fechaFormularioF').each(function(){
			if($(this).is(":visible")){
				var formato = $(this).attr("dateformat");
				switch(formato){
					case "dd-mm-yy":
						if(!autoValidateDMY("-",$(this).val())){
							resultado = false;
							$(this).closest(".form-group").addClass("has-error");
							labels.push($(this).closest(".form-group").find(".control-label").text()+" "+$('.form_'+cont_id).attr("texto-error")+" (yyyy-mm-dd)");
							$(this).closest(".form-group").find(".errorText").text($('.form_'+cont_id).attr("texto-error")+" (yyyy-mm-dd)");
						}
					break;
					case "dd/mm/yy":
						if(!autoValidateDMY("/",$(this).val())){
							resultado = false;
							$(this).closest(".form-group").addClass("has-error");
							labels.push($(this).closest(".form-group").find(".control-label").text()+" "+$('.form_'+cont_id).attr("texto-error")+" (yyyy-mm-dd)");
							$(this).closest(".form-group").find(".errorText").text($('.form_'+cont_id).attr("texto-error")+" (yyyy-mm-dd)");
						}
					break;
					case "yy/mm/dd":
						if(!autoValidateYMDY("/",$(this).val())){
							resultado = false;
							$(this).closest(".form-group").addClass("has-error");
							labels.push($(this).closest(".form-group").find(".control-label").text()+" "+$('.form_'+cont_id).attr("texto-error")+" (yyyy-mm-dd)");
							$(this).closest(".form-group").find(".errorText").text($('.form_'+cont_id).attr("texto-error")+" (yyyy-mm-dd)");
						}
					break;
					case "yy-mm-dd":
						if(!autoValidateYMDY("-",$(this).val())){
							resultado = false;
							$(this).closest(".form-group").addClass("has-error");
							labels.push($(this).closest(".form-group").find(".control-label").text()+" "+$('.form_'+cont_id).attr("texto-error")+" (yyyy-mm-dd)");
							$(this).closest(".form-group").find(".errorText").text($('.form_'+cont_id).attr("texto-error")+" (yyyy-mm-dd)");
						}
					break;
				}
			}
		})
	}
	if(labels.length>0){
		$('.form_'+cont_id+' .camposErroneos').html(labels.join("<br/>"));
		$('.form_'+cont_id+' .alertR').show();
		if($('.form_'+cont_id+' .alertR').is(":visible")){
			$("html, body").animate({ scrollTop: $('.form_'+cont_id+' .alertR').offset().top-150 }, 500);
		}
	}
	return resultado;
}

function sendBloqueFormulario(cont_id){
	$('.form_'+cont_id+' .btn-enviar').hide();
	$('.form_'+cont_id+' .btn-enviando').show();
	//console.log($('.form_'+cont_id+' .btn-enviando').length);
	$('.form_'+cont_id+' .btn-enviarForm').prop("disabled",true);
	var csrfToken = $('meta[name="csrf-token"]').attr("content");
    var json = $('.form_'+cont_id).serializeObject();
    var form = document.querySelector('.form_'+cont_id);
  	var formData = new FormData(form);
  	
	formData.append("_csrf", csrfToken);
	formData.append("lang_id", $('.form_'+cont_id).attr("data-langid"));
	formData.append("cont_id", cont_id);
	formData.append("json", JSON.stringify(json));
	formData.append("page", window.location.href);
	$('.form_'+cont_id+' .msgOK,.form_'+cont_id+' .msgKO').hide();
	var baseurl = $('.form_'+cont_id).attr("data-baseurl");
	setTimeout(function(){
		$.ajax({
	        url: baseurl+"/ajax/sendformulario",
	        type: 'POST',
	        data: formData,
	        dataType: 'json',
	        async: false,
	        success: function (data) {
	            if(data.onsentok.trim()!="" && data.code==1){
					eval(data.onsentok);
				}
				if(data.onsubmit.trim()!="" && data.code!=1){
					eval(data.onsubmit);
				}
				if(data.code==1){
					$('.form_'+cont_id+' .msgOK').show();
					$('.form_'+cont_id).addClass('sended');
					$('.form_'+cont_id+' input[type="text"]').val("");
				}else{
					$('.form_'+cont_id+' .msgKO').show();
				}
				$('.form_'+cont_id+' .btn-enviarForm').prop("disabled",false);
				$('.form_'+cont_id+' .btn-enviar').show();
				$('.form_'+cont_id+' .btn-enviando').hide();

				var window_height = $(window).height();
			    var window_top_position = $(window).scrollTop();
			    if ($('.form_'+cont_id+' .msgOK').offset().top > (window_top_position + window_height * 0.75) || $('.form_'+cont_id+' .msgOK').offset().top < (window_top_position + 150) ){
					if ($(window).width() < 740){
						$("html, body").animate({ scrollTop: $('.form_'+cont_id+' .msgOK').offset().top-120 }, 400);
					} else{
						$("html, body").animate({ scrollTop: $('.form_'+cont_id+' .msgOK').offset().top-200 }, 400);
					}
			    }
			    if ($('.form_'+cont_id+' .msgKO').offset().top > (window_top_position + window_height * 0.75) || $('.form_'+cont_id+' .msgKO').offset().top < (window_top_position + 150) ){
					if ($(window).width() < 740){
						$("html, body").animate({ scrollTop: $('.form_'+cont_id+' .msgKO').offset().top-120 }, 400);
					} else{
						$("html, body").animate({ scrollTop: $('.form_'+cont_id+' .msgKO').offset().top-200 }, 400);
					}
			    }
	        },
	        cache: false,
	        contentType: false,
	        processData: false
	    });
	},500)
	
	/*$.post("/ajax/sendformulario", dataValue ,function(data){
		if(data.onsentok.trim()!="" && data.code==1){
			eval(data.onsentok);
		}
		if(data.onsubmit.trim()!=""){
			eval(data.onsubmit);
		}
		if(data.code==1){
			$('.form_'+cont_id+' .msgOK').show();
		}else{
			$('.form_'+cont_id+' .msgKO').show();
		}
		$('.form_'+cont_id+' .btn-enviarForm').prop("disabled",false);
		$('.form_'+cont_id+' .btn-enviar').show();
		$('.form_'+cont_id+' .btn-enviando').hide();
	},"json");*/
}

$(document).on("click",".btn-closeAlert",function(){
	$(this).closest(".alert").hide();
})