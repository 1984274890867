(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name Proyecto
* @description
* This is main controller
**/ 

    angular
    .module('app')
    .controller('Proyecto', Proyecto);

    Proyecto.$inject = ["ProjectService","$timeout","$location",'$anchorScroll'];

    function Proyecto(ProjectService,$timeout,$location,$anchorScroll)
    {
        var vm = this;
        vm.projects = "";
        vm.currentCat = "";
        
    	vm.showProjects = function(cat_id){
     		ProjectService.getProjects(cat_id).then(function(data){ 
            	vm.projects = data.projects;
                // $timeout(function(){
                //     handleLinkHoverEvents();
                // })
        	})
 		}

    }


})();