   const cur = document.querySelector('.c-cursor');
   const follow = document.querySelector('.c-cursor__hover');
   const onMouseMove = (e) => {
      // gsap
      gsap.set(cur, {x:e.clientX, y:e.clientY});
      gsap.to(follow, {x:e.clientX, y:e.clientY, duration: 0.3});
   };

   // const onMouseDown = () => {
   //     cursorEl.classList.add(isClickedClass);
   // };

   // const onMouseUp = () => {
   //     cursorEl.classList.remove(isClickedClass);
   // };


   const onMouseEnter = () => {
      gsap.to(cur, {opacity: 1});
      gsap.to(follow, {opacity: 1});
   };

   const onMouseLeave = () => {
      gsap.to(cur, {opacity: 0});
      gsap.to(follow, {opacity: 0});
   };

   const addEventListeners = () => {
      document.addEventListener("mousemove", onMouseMove);
      // document.addEventListener("mousedown", onMouseDown);
      // document.addEventListener("mouseup", onMouseUp);
      document.addEventListener("mouseenter", onMouseEnter);
      document.addEventListener("mouseleave", onMouseLeave);

      handleLinkHoverEvents();
   };

   const handleLinkHoverEvents = () => {
      document.querySelectorAll('a, button, .js-link, input[type="button"], input[type="submit"]').forEach((el) => {
         el.addEventListener("mouseover", () => {
               gsap.to(cur, {width: 40, height: 40, background: '#00b27f'});
               gsap.to(follow, {opacity: 0});
         } );
         el.addEventListener("mouseout", () => {
               gsap.to(cur, {width: 10, height: 10, background: '#153370'});
               gsap.to(follow, {opacity: 1});
         } );
      });
   };

   //
     // canvas
     var canvas, stage, exportRoot, anim_container, dom_overlay_container, fnStartAnimation;
   
     function canvas_init() {
           canvas = document.getElementById("canvas");
           anim_container = document.getElementById("animation_container");
           dom_overlay_container = document.getElementById("dom_overlay_container");
  
           if($("#animation_container").hasClass('nose')){
  
              var comp=AdobeAn.getComposition("CEF820B6B8A948708F58E96F12CA2B54");
  
           }else if($("#animation_container").hasClass('heart')){
  
              var comp=AdobeAn.getComposition("DFDE106AEAC54924969250FB54434D32");
  
           }else{
  
              var comp=AdobeAn.getComposition("B420F19D249F4430BB8874BB9C700CAA");
           }
  
  
           var lib=comp.getLibrary();
           handleComplete({},comp);
     }
     function handleComplete(evt,comp) {
           //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
           var lib=comp.getLibrary();
           var ss=comp.getSpriteSheet();
  
           
           if($("#animation_container").hasClass('nose')){
  
              exportRoot = new lib.nase();
              
           }else if($("#animation_container").hasClass('heart')){
  
              exportRoot = new lib.herz();
  
           }else{
  
              exportRoot = new lib.auge();
           }
  
           stage = new lib.Stage(canvas);	
           //Registers the "tick" event listener.
           fnStartAnimation = function() {
              stage.addChild(exportRoot);
              createjs.Ticker.framerate = lib.properties.fps;
              createjs.Ticker.addEventListener("tick", stage);
           }	    
           //Code to support hidpi screens and responsive scaling.
           AdobeAn.makeResponsive(true,'both',true,1,[canvas,anim_container,dom_overlay_container]);	
           AdobeAn.compositionLoaded(lib.properties.id);
           fnStartAnimation();
     }
  
   //
   // var touch = Modernizr.touch;

   // menu mobile
   var $openMenu = $("#js-open-menu"),
   $closeMenu = $("#js-close-menu"),
   $menuWrap = $("#js-menu-wrap");

   $openMenu.on("click", function(){
      $menuWrap.addClass("is-show");
      $("html").addClass("no-scroll");
      $(".b-menu").addClass("is-active");
   });

   $closeMenu.on("click", function(){
      $menuWrap.removeClass("is-show");
      $("html").removeClass("no-scroll");
      $(".b-menu").removeClass("is-active");
   });

   // carrousel of brads
   var swiper = new Swiper(".mySwiper", {
      slidesPerView: "auto",
      speed: 5000,
      autoplay: {
         delay: 1,
         disableOnInteraction: false,
      },
      loop: true,

      freeMode: true,
      freeModeMomentum: false
   });
    

   // select
   $(".c-text__list-group-link").on("click", function () {
      $("#c-text__button").trigger("click");
      return false;
   });

   // plus line rotate 0deg
   $(".c-accordion__btn").on("click", function(){
      $(this).toggleClass("is-rotate");
   });

   // angle down rotate 360deg
   $("#c-text__button").on("click", function(){
      $("#c-text__arrow").toggleClass("is-rotate");
   });

   // back to previous page
   var btn = $('#js-back');

   $(window).scroll(function() {
      if ($(window).scrollTop() > 300) {
         btn.addClass('is-show');
      } else {
         btn.removeClass('is-show');
      }
   });

   // svg color depends of page
   var targetClass = $(".js-logo-color"),
      logoClass = $(".b-header__logo");

   if(targetClass.hasClass("o-bg-gradient")) {
      logoClass.addClass("text-white");
   }else {
      logoClass.addClass("text-holyred");
   }

   // gsap with scrolltrigger
   
   gsap.registerPlugin(ScrollTrigger);

   // module animation inside foreach
   const reveal = gsap.utils.toArray('.slideanim')


   // custom cursor
   // https://stackoverflow.com/a/4819886
   function isTouchDevice() {
      return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
   }

   const isTouch = isTouchDevice();

   if (!isTouch){

      const cursorEl = document.querySelector('.js-cursor');
      // const isClickedClass = 'is-clicked';
      const hasCustomCursorClass = 'has-custom-cursor';

      // gsap reset center
      gsap.set('.c-cursor', {xPercent:-50, yPercent:-50});
      gsap.set('.c-cursor__hover', {xPercent:-50, yPercent:-50});

      $('.js-iframe').mouseenter(function(){

         gsap.to(cur, {opacity: 0});
         gsap.to(follow, {opacity: 0});
      });
      
      $('.js-iframe').mouseleave(function(){

         gsap.to(cur, {opacity: 1});
         gsap.to(follow, {opacity: 1});
      });

      addEventListeners();
      document.body.classList.add(hasCustomCursorClass);

   }

   $(window).on("load", function(){

         if($("#animation_container").length){

            canvas_init();

            gsap.to("#main", {

               scrollTrigger:{
                  trigger: ".js-scrolltrigger",
                  start: "bottom bottom",
                  end: "+=100%",
                  pin: true,
                  pinSpacing: false,
                  // markers: true
               }
            });
         
         }

         
   reveal.forEach((module, i) => {
      ScrollTrigger.create({
         trigger: module,
         start: "top+=100px bottom",
         toggleClass: "show",
         once: true,
         // markers: true

      })
   })

   });
