if($("#animation_container").hasClass('nose')){

    (function (cjs, an) {

        var p; // shortcut to reference prototypes
        var lib={};var ss={};var img={};
        lib.ssMetadata = [];
        
        
        (lib.AnMovieClip = function(){
            this.actionFrames = [];
            this.gotoAndPlay = function(positionOrLabel){
                cjs.MovieClip.prototype.gotoAndPlay.call(this,positionOrLabel);
            }
            this.play = function(){
                cjs.MovieClip.prototype.play.call(this);
            }
            this.gotoAndStop = function(positionOrLabel){
                cjs.MovieClip.prototype.gotoAndStop.call(this,positionOrLabel);
            }
            this.stop = function(){
                cjs.MovieClip.prototype.stop.call(this);
            }
        }).prototype = p = new cjs.MovieClip();
        // symbols:
        
        
        
        // stage content:
        (lib.nase = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_7
            this.shape = new cjs.Shape();
            this.shape.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],5.4,27.2,-10.2,-30.9).s().p("ArvF7QhFgcgPg8QgOg5A3hMQA1hJBrhPQDbieCWhMQEZiQEfgRQEvgSCMBzQBnBUgLB5QgHBWg8AyQg6AxiCAgQhnAahrgGQhkgGiIgmQiQgohGgCQhbgDhjAtQhFAghtBxQhoBtg0ATQgoAOglAAQgmAAgjgOg");
            this.shape.setTransform(511.5815,502.0407);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],5.2,27.3,-10.4,-30.8).s().p("ArxF8QhFgcgPg8QgOg5A2hNQA0hKBthRQDWicCbhPQEYiPEhgQQEwgRCMBxQBqBVgMB6QgIBYg9AyQg8AxiCAgQhnAahrgGQhkgGiIgmQiQgohGgCQhbgDhjAtQhFAghtBxQhoBtg0ATQgoAOglAAQgmAAgjgOg");
            this.shape_1.setTransform(511.7874,501.9018);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],5,27.5,-10.6,-30.6).s().p("ArzF9QhFgbgPg8QgOg6A0hOQAzhLBvhTQDRiZChhSQEWiOEjgQQEzgQCMBwQBrBWgMB6QgJBbg/AyQg9AxiCAgQhnAahrgHQhkgGiIglQiQgohGgDQhbgDhjAuQhFAfhtByQhoBsg0ATQgoAPglAAQgmAAgjgPg");
            this.shape_2.setTransform(511.9879,501.757);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],4.8,27.6,-10.8,-30.5).s().p("Ar1F/QhFgcgPg8QgOg5AzhPQAyhNBwhTQDNiZCnhUQEUiOElgOQE0gQCMBvQBuBWgNB7QgJBfhBAxQg/AwiCAgQhnAahrgGQhkgGiIgmQiQgohGgCQhbgDhjAtQhFAghtBxQhoBtg0ATQgoAOglAAQgmAAgjgOg");
            this.shape_3.setTransform(512.1941,501.6175);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],4.6,27.8,-11,-30.3).s().p("Ar3GAQhFgbgPg8QgOg6AyhQQAxhOByhVQDIiWCshXQESiNEogOQE1gOCNBtQBwBWgOB9QgKBhhCAxQhBAwiCAgQhnAahrgHQhkgGiIglQiQgohGgDQhbgDhjAuQhFAfhtByQhoBsg0ATQgoAOglAAQgmAAgjgOg");
            this.shape_4.setTransform(512.3957,501.4777);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],4.4,27.9,-11.2,-30.2).s().p("Ar5GCQhFgcgPg7QgOg6AxhRQAwhPBzhYQDEiTCyhbQERiLEpgNQE2gOCNBtQByBWgOB9QgLBkhEAxQhCAviCAgQhnAahrgGQhkgGiIgmQiQgohGgCQhbgDhjAtQhFAghtBxQhoBtg0ATQgoAOglAAQgmAAgjgOg");
            this.shape_5.setTransform(512.5957,501.3378);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],4.2,28.1,-11.4,-30).s().p("Ar8GDQhFgbgPg8QgOg6AwhSQAvhQB1haQC/iQC3heQEQiKErgNQE4gNCNBsQB1BWgPB/QgNBmhFAwQhEAwiCAgQhnAahqgHQhlgGiHglQiQgohHgDQhbgDhjAuQhEAfhtByQhoBsg1ATQgnAOglAAQgmAAgkgOg");
            this.shape_6.setTransform(512.8022,501.1924);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],4,28.2,-11.6,-29.9).s().p("Ar+GEQhFgbgPg8QgOg6AvhTQAuhRB2hcQC7iOC9hgQEOiKEtgMQE5gMCNBqQB3BXgPCAQgNBphHAwQhGAviCAgQhnAahqgHQhlgGiHglQiQgohHgDQhbgDhjAuQhEAfhtByQhoBsg1ATQgnAPglAAQgmAAgkgPg");
            this.shape_7.setTransform(513.0037,501.0515);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],3.8,28.3,-11.8,-29.8).s().p("AsAGGQhFgcgPg7QgOg6AuhUQAthTB4heQC1iLDDhkQEMiIEwgLQE6gLCOBoQB5BYgQCAQgOBshIAwQhIAuiCAgQhnAahqgGQhlgGiHgmQiQgohHgCQhbgDhjAtQhEAghtBxQhoBtg1ATQgnAOglAAQgmAAgkgOg");
            this.shape_8.setTransform(513.2089,500.9101);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],3.6,28.5,-12,-29.6).s().p("AsCGHQhFgbgOg8QgPg6AthVQAshTB5hgQCxiKDJhmQEKiIEygJQE7gLCOBnQB8BYgRCCQgPBvhKAvQhJAuiCAgQhnAahqgHQhlgGiHglQiQgohHgDQhbgDhjAuQhEAfhtByQhoBsg1ATQgnAOgmAAQglAAgkgOg");
            this.shape_9.setTransform(513.4493,500.7889);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],3.4,28.6,-12.2,-29.5).s().p("AsEGJQhFgcgOg7QgPg7AshWQAqhUB8hiQCsiHDOhpQEIiHE1gJQE8gKCPBmQB+BYgSCDQgQByhLAuQhLAuiCAgQhnAahqgGQhlgGiHgmQiQgohHgCQhbgDhjAtQhEAghtBxQhoBtg1ATQgnAOgmAAQglAAgkgOg");
            this.shape_10.setTransform(513.641,500.6429);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],3.2,28.7,-12.4,-29.4).s().p("AsGGKQhFgcgOg7QgPg7AqhXQAqhVB9hkQCoiFDThsQEHiGE2gIQE+gICPBkQCBBZgTCDQgRB1hNAuQhMAtiCAgQhnAahqgGQhlgGiHgmQiQgohHgCQhbgDhjAtQhEAghtBxQhoBtg1ATQgnAOgmAAQglAAgkgOg");
            this.shape_11.setTransform(513.8458,500.5005);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],3,28.9,-12.6,-29.2).s().p("AsIGLQhFgbgOg8QgPg6AphYQAphXB+hmQCkiCDZhvQEEiFE5gHQE/gICQBjQCCBZgTCEQgSB4hOAuQhOAtiCAgQhnAahqgHQhlgGiHglQiQgohHgDQhbgDhjAuQhEAfhtByQhoBsg1ATQgnAPgmAAQglAAgkgPg");
            this.shape_12.setTransform(514.0466,500.3576);
        
            this.shape_13 = new cjs.Shape();
            this.shape_13.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],2.7,29,-12.9,-29.1).s().p("AsKGNQhFgcgPg7QgPg7AphZQAnhYCAhoQCfh/DehyQEDiEE8gHQFAgHCQBiQCFBZgUCGQgTB6hQAtQhPAtiCAgQhnAahrgGQhkgGiIgmQiQgohGgCQhbgDhjAtQhFAghtBxQhoBtg0ATQgnAOgmAAQglAAgkgOg");
            this.shape_13.setTransform(514.253,500.2108);
        
            this.shape_14 = new cjs.Shape();
            this.shape_14.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],2.5,29.1,-13.1,-29).s().p("AsMGOQhFgbgPg7QgPg7AnhaQAnhZCChqQCah+Dkh0QEBiEE9gFQFCgHCQBhQCHBagUCGQgUB+hRAsQhRAtiCAgQhnAahrgHQhkgGiIglQiQgohGgDQhbgDhjAuQhFAfhtByQhoBsg0ATQgnAPgmAAQglAAgkgPg");
            this.shape_14.setTransform(514.4514,500.0671);
        
            this.shape_15 = new cjs.Shape();
            this.shape_15.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],2.3,29.3,-13.3,-28.8).s().p("AsOGQQhFgcgPg7QgPg7AmhbQAmhaCDhsQCVh7Dqh3QEAiDE/gFQFDgFCQBfQCKBagVCIQgVCAhTAsQhSAsiCAgQhnAahrgGQhkgGiIgmQiQgohGgCQhbgDhjAtQhFAghtBxQhoBtg0ATQgnAOgmAAQglAAgkgOg");
            this.shape_15.setTransform(514.6519,499.9225);
        
            this.shape_16 = new cjs.Shape();
            this.shape_16.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],2.1,29.4,-13.5,-28.7).s().p("AsQGRQhFgbgPg7QgPg7AlhcQAlhcCEhuQCRh4Dwh6QD9iCFCgEQFEgFCRBeQCMBagWCJQgWCDhUAsQhUAsiCAgQhnAahrgHQhkgGiIglQiQgohGgDQhbgDhjAuQhFAfhtByQhoBsg0ATQgnAOgmAAQglAAgkgOg");
            this.shape_16.setTransform(514.858,499.7772);
        
            this.shape_17 = new cjs.Shape();
            this.shape_17.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],1.9,29.6,-13.7,-28.5).s().p("AsSGTQhFgcgPg7QgPg7AkhdQAjhdCHhwQCMh1D1h+QD8iAFEgEQFGgECQBdQCOBbgWCJQgXCGhVAsQhWAriCAgQhnAahrgGQhkgGiIgmQiQgohGgCQhbgDhjAtQhFAghtBxQhoBtg0ATQgnAOgmAAQglAAgkgOg");
            this.shape_17.setTransform(515.0561,499.6293);
        
            this.shape_18 = new cjs.Shape();
            this.shape_18.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],1.7,29.7,-13.9,-28.4).s().p("AsUGUQhFgbgPg7QgPg7AjheQAiheCIhyQCIh0D6iAQD6iAFHgCQFHgDCRBbQCRBbgYCLQgYCJhXArQhXAriCAgQhnAahrgHQhkgGiIglQiQgohGgDQhbgDhjAuQhFAfhtByQhoBsg0ATQgnAPgmAAQglAAgkgPg");
            this.shape_18.setTransform(515.2584,499.4706);
        
            this.shape_19 = new cjs.Shape();
            this.shape_19.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],1.9,29.6,-13.7,-28.5).s().p("AsSGTQhFgcgPg7QgPg7AkhdQAjhdCHhwQCLh2D2h9QD7iBFFgDQFGgECRBdQCOBagXCKQgWCHhWArQhWAriCAgQhnAahrgGQhkgGiIgmQiQgohGgCQhbgDhjAtQhFAghtBxQhoBtg0ATQgoAOglAAQgmAAgjgOg");
            this.shape_19.setTransform(515.0871,499.6061);
        
            this.shape_20 = new cjs.Shape();
            this.shape_20.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],2.1,29.5,-13.5,-28.6).s().p("AsRGSQhFgcgOg7QgPg7AkhcQAlhcCFhvQCPh3Dxh7QD9iCFDgEQFFgECQBeQCNBagXCJQgWCEhUAsQhVAriCAgQhnAahqgGQhlgGiHgmQiQgohHgCQhbgDhjAtQhEAghtBxQhoBtg1ATQgnAOglAAQgmAAgkgOg");
            this.shape_20.setTransform(514.9118,499.7272);
        
            this.shape_21 = new cjs.Shape();
            this.shape_21.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],2.3,29.4,-13.3,-28.7).s().p("AsPGRQhFgcgOg7QgPg7AlhbQAlhbCEhtQCUh6Dsh5QD+iCFBgFQFEgFCQBfQCLBagWCIQgVCChTAsQhUAsiCAgQhnAahqgHQhlgFiHgmQiQgohHgCQhbgDhjAtQhEAfhtByQhoBsg1AUQgnAOgmAAQglAAgkgOg");
            this.shape_21.setTransform(514.736,499.85);
        
            this.shape_22 = new cjs.Shape();
            this.shape_22.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],2.4,29.2,-13.2,-28.9).s().p("AsNGPQhFgbgPg7QgPg7AnhbQAmhaCChrQCYh8Dnh2QEAiDE/gFQFCgGCQBgQCJBagVCHQgUB/hSAtQhSAsiCAgQhnAahrgHQhkgGiIglQiQgohGgDQhbgDhjAuQhFAfhtByQhoBsg0ATQgnAPgmAAQglAAgkgPg");
            this.shape_22.setTransform(514.5547,499.9726);
        
            this.shape_23 = new cjs.Shape();
            this.shape_23.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],2.6,29.1,-13,-29).s().p("AsLGOQhFgbgPg7QgPg7AohaQAnhZCBhpQCbh+Djh0QEBiEE9gGQFCgGCPBhQCHBZgVCHQgTB8hRAtQhQAtiCAgQhnAahrgHQhkgGiIglQiQgohGgDQhbgDhjAuQhFAfhtByQhoBsg0ATQgoAOglAAQgmAAgjgOg");
            this.shape_23.setTransform(514.3791,500.0922);
        
            this.shape_24 = new cjs.Shape();
            this.shape_24.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],2.8,29,-12.8,-29.1).s().p("AsKGNQhFgcgOg7QgPg7AohYQAohYCAhoQCfiADehxQEDiFE7gGQFAgICQBiQCEBagTCFQgTB6hQAtQhPAtiCAgQhnAahqgGQhlgGiHgmQiQgohHgCQhbgDhjAtQhEAghtBxQhoBtg1ATQgnAOglAAQgmAAgkgOg");
            this.shape_24.setTransform(514.2224,500.2358);
        
            this.shape_25 = new cjs.Shape();
            this.shape_25.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],3.1,28.8,-12.5,-29.3).s().p("AsGGKQhFgbgPg8QgPg6ArhXQAphWB9hkQCoiFDUhsQEGiGE3gIQE+gJCPBlQCBBYgTCEQgRB1hNAuQhMAuiCAgQhnAahrgHQhkgGiIglQiQgohGgDQhbgDhjAuQhFAfhtByQhoBsg0ATQgnAOgmAAQglAAgkgOg");
            this.shape_25.setTransform(513.8704,500.4755);
        
            this.shape_26 = new cjs.Shape();
            this.shape_26.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],3.3,28.7,-12.3,-29.4).s().p("AsEGJQhFgbgPg8QgPg6AshWQAqhVB8hjQCriGDPhqQEIiHE1gIQE9gKCPBmQB/BYgSCDQgRBzhLAuQhLAuiCAgQhnAahrgHQhkgGiIglQiQgohGgDQhbgDhjAuQhFAfhtByQhoBsg0ATQgoAOglAAQgmAAgjgOg");
            this.shape_26.setTransform(513.6944,500.5969);
        
            this.shape_27 = new cjs.Shape();
            this.shape_27.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],3.5,28.5,-12.1,-29.6).s().p("AsDGIQhFgcgOg7QgPg7AshVQAshUB6hhQCviIDLhnQEJiIEzgJQE8gKCOBmQB9BYgRCCQgQBxhKAuQhKAuiCAgQhnAahqgGQhlgGiHgmQiQgohHgCQhbgDhjAtQhEAghtBxQhoBtg1ATQgnAOglAAQgmAAgkgOg");
            this.shape_27.setTransform(513.5184,500.7181);
        
            this.shape_28 = new cjs.Shape();
            this.shape_28.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],3.7,28.4,-11.9,-29.7).s().p("AsBGHQhFgcgPg7QgOg6AthVQAshTB6hfQCyiKDGhlQELiJExgKQE7gLCOBoQB7BYgRCBQgPBuhIAvQhJAuiCAgQhnAahqgGQhlgGiHgmQiQgohHgCQhbgDhjAtQhEAghtBxQhoBtg1ATQgnAOgmAAQglAAgkgOg");
            this.shape_28.setTransform(513.3318,500.8349);
        
            this.shape_29 = new cjs.Shape();
            this.shape_29.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],3.8,28.3,-11.8,-29.8).s().p("Ar/GFQhFgbgPg8QgOg6AuhUQAthSB4hdQC2iMDChjQEMiJEvgLQE6gLCOBpQB4BXgQCAQgOBshHAvQhHAviCAgQhnAahrgHQhkgGiIglQiQgohGgDQhbgDhjAuQhFAfhtByQhoBsg0ATQgnAPgmAAQglAAgkgPg");
            this.shape_29.setTransform(513.1554,500.9557);
        
            this.shape_30 = new cjs.Shape();
            this.shape_30.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],4,28.2,-11.6,-29.9).s().p("Ar9GEQhFgbgPg8QgOg6AvhTQAuhRB2hbQC7iPC8hgQEOiKEtgLQE5gMCOBqQB2BXgPB/QgNBphHAwQhFAviCAgQhnAahrgHQhkgGiIglQiQgohGgDQhbgDhjAuQhFAfhtByQhoBsg0ATQgoAOglAAQgmAAgjgOg");
            this.shape_30.setTransform(512.979,501.0765);
        
            this.shape_31 = new cjs.Shape();
            this.shape_31.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],4.4,27.9,-11.2,-30.2).s().p("Ar6GCQhFgcgPg7QgOg6AxhSQAwhPB0hXQDCiTCzhbQERiMEqgNQE2gNCNBsQBzBWgPB+QgLBkhEAxQhDAviCAgQhnAahqgGQhlgGiHgmQiQgohHgCQhbgDhjAtQhEAghtBxQhoBtg1ATQgnAOgmAAQglAAgkgOg");
            this.shape_31.setTransform(512.6256,501.3128);
        
            this.shape_32 = new cjs.Shape();
            this.shape_32.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],4.5,27.8,-11.1,-30.3).s().p("Ar4GAQhFgbgPg8QgOg6AyhQQAwhOBzhWQDGiVCuhZQESiMEogNQE1gPCNBtQBxBXgNB8QgLBihDAxQhBAwiCAgQhnAahrgHQhkgGiIglQiQgohGgDQhbgDhjAuQhFAfhtByQhoBsg0ATQgnAPgmAAQglAAgkgPg");
            this.shape_32.setTransform(512.4689,501.4524);
        
            this.shape_33 = new cjs.Shape();
            this.shape_33.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],4.7,27.7,-10.9,-30.4).s().p("Ar2F/QhFgbgPg8QgOg6AzhPQAxhNBxhVQDLiXCphWQETiNEngOQE0gPCMBuQBvBWgNB8QgKBfhBAxQhAAxiCAgQhnAahrgHQhkgGiIglQiQgohGgDQhbgDhjAuQhFAfhtByQhoBsg0ATQgoAPglAAQgmAAgjgPg");
            this.shape_33.setTransform(512.2924,501.5672);
        
            this.shape_34 = new cjs.Shape();
            this.shape_34.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],4.9,27.6,-10.7,-30.5).s().p("Ar1F+QhFgbgPg8QgOg6A0hOQAzhMBvhTQDPiZCkhUQEViNEkgPQEzgQCNBvQBsBWgMB7QgJBdhAAxQg/AxiCAgQhnAahqgHQhlgGiHglQiQgohHgDQhbgDhjAuQhEAfhtByQhoBsg1ATQgnAOglAAQgmAAgkgOg");
            this.shape_34.setTransform(512.1113,501.6871);
        
            this.shape_35 = new cjs.Shape();
            this.shape_35.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],5.1,27.4,-10.5,-30.7).s().p("ArzF9QhFgcgPg8QgOg5A1hOQAzhLBvhSQDSiaCghRQEWiPEjgPQExgRCMBxQBrBVgLB6QgJBbg+AxQg+AxiCAgQhnAahqgGQhlgGiHgmQiQgohHgCQhbgDhjAtQhEAghtBxQhoBtg1ATQgnAOgmAAQglAAgkgOg");
            this.shape_35.setTransform(511.9345,501.807);
        
            this.shape_36 = new cjs.Shape();
            this.shape_36.graphics.lf(["rgba(0,0,0,0.098)","#393735"],[0,1],5.2,27.3,-10.4,-30.8).s().p("ArxF8QhFgcgPg8QgOg5A2hNQA0hKBthQQDWidCbhOQEYiPEggRQExgRCMByQBpBVgLB5QgIBYg9AyQg8AxiCAgQhnAahrgGQhkgGiIgmQiQgohGgCQhbgDhjAtQhFAghtBxQhoBtg0ATQgnAOgmAAQglAAgkgOg");
            this.shape_36.setTransform(511.758,501.9211);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape}]}).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_4}]},1).to({state:[{t:this.shape_5}]},1).to({state:[{t:this.shape_6}]},1).to({state:[{t:this.shape_7}]},1).to({state:[{t:this.shape_8}]},1).to({state:[{t:this.shape_9}]},1).to({state:[{t:this.shape_10}]},1).to({state:[{t:this.shape_11}]},1).to({state:[{t:this.shape_12}]},1).to({state:[{t:this.shape_13}]},1).to({state:[{t:this.shape_14}]},1).to({state:[{t:this.shape_15}]},1).to({state:[{t:this.shape_16}]},1).to({state:[{t:this.shape_17}]},1).to({state:[{t:this.shape_18}]},1).to({state:[{t:this.shape_19}]},1).to({state:[{t:this.shape_20}]},1).to({state:[{t:this.shape_21}]},1).to({state:[{t:this.shape_22}]},1).to({state:[{t:this.shape_23}]},1).to({state:[{t:this.shape_24}]},1).to({state:[{t:this.shape_12}]},1).to({state:[{t:this.shape_25}]},1).to({state:[{t:this.shape_26}]},1).to({state:[{t:this.shape_27}]},1).to({state:[{t:this.shape_28}]},1).to({state:[{t:this.shape_29}]},1).to({state:[{t:this.shape_30}]},1).to({state:[{t:this.shape_6}]},1).to({state:[{t:this.shape_31}]},1).to({state:[{t:this.shape_32}]},1).to({state:[{t:this.shape_33}]},1).to({state:[{t:this.shape_34}]},1).to({state:[{t:this.shape_35}]},1).to({state:[{t:this.shape_36}]},1).to({state:[{t:this.shape}]},1).wait(1));
        
            // Schatten
            this.shape_37 = new cjs.Shape();
            this.shape_37.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,33.8,7,-33.7).s().p("AoD1HIQHdJIigAYQi4Akh2BBQkjCkidEBQg1BWgnBwQgTA3gJAmg");
            this.shape_37.setTransform(365.075,339);
        
            this.shape_38 = new cjs.Shape();
            this.shape_38.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,34.1,7,-33.4).s().p("AoD1DIQHdJIiiAVQi3Ahh8BEQkfChibD+Qg3BdglBvQgTA6gIAfg");
            this.shape_38.setTransform(365.075,338.675);
        
            this.shape_39 = new cjs.Shape();
            this.shape_39.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,34.4,7,-33.1).s().p("AoD1AIQHdJIikATQi2AfiBBGQkdCfiXD7Qg6BjgkBvIgZBUg");
            this.shape_39.setTransform(365.075,338.325);
        
            this.shape_40 = new cjs.Shape();
            this.shape_40.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,34.8,7,-32.7).s().p("AoD09IQHdJIimARQi1AciHBJQkZCciUD5Qg9BpgiBuQgSA+gGARg");
            this.shape_40.setTransform(365.075,338);
        
            this.shape_41 = new cjs.Shape();
            this.shape_41.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,35.1,7,-32.4).s().p("AoD05IQHdJIioAPQi0AZiMBMQkWCZiSD2Qg+BvghBuQgSBAgFAKg");
            this.shape_41.setTransform(365.075,337.65);
        
            this.shape_42 = new cjs.Shape();
            this.shape_42.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,35.4,7,-32.1).s().p("AoD02IQHdJIiqANQizAXiRBNQkTCXiPD0QhBB1ggBtQgQBDgFADg");
            this.shape_42.setTransform(365.075,337.325);
        
            this.shape_43 = new cjs.Shape();
            this.shape_43.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,35.8,7,-31.7).s().p("AoCUzMgABgplIQHdJIisALQiyAUiXBPQkPCUiNDyQhDB7geBtQgPBAgFAAIAAAAg");
            this.shape_43.setTransform(365.075,336.9918);
        
            this.shape_44 = new cjs.Shape();
            this.shape_44.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,36,7,-31.5).s().p("AoCUvMgABgpfIQHdJIiuAJQixASicBSQkMCRiKDvQhGCBgcBsQgOA+gEAAIgBgCg");
            this.shape_44.setTransform(365.075,336.7357);
        
            this.shape_45 = new cjs.Shape();
            this.shape_45.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,36.2,7,-31.3).s().p("AoCUqMgABgpYIQHdJIiwAHQiwAPiiBUQkJCPiGDtQhJCHgbBsQgMA7gEAAQAAAAAAgBQgBAAAAAAQAAgBAAgBQAAgBAAgBg");
            this.shape_45.setTransform(365.075,336.5033);
        
            this.shape_46 = new cjs.Shape();
            this.shape_46.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,36.4,7,-31.1).s().p("AoCUmMgABgpSIQHdJIiyAFQivAMimBXQkHCMiEDrQhKCNgaBrQgLA5gEAAQgBAAAAgHg");
            this.shape_46.setTransform(365.075,336.3159);
        
            this.shape_47 = new cjs.Shape();
            this.shape_47.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,36.6,7,-30.9).s().p("AoCUhMgABgpLIQHdJIizADQivAJisBZQkDCKiBDoQhNCTgYBrQgLA3gDAAQgBAAAAgKg");
            this.shape_47.setTransform(365.075,336.151);
        
            this.shape_48 = new cjs.Shape();
            this.shape_48.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,36.8,7,-30.7).s().p("AoCUcMgABgpEIQHdJIi1AAQivAIiwBbQkACHh+DlQhQCZgXBrQgJA1gDAAQgCAAABgNg");
            this.shape_48.setTransform(365.075,335.9778);
        
            this.shape_49 = new cjs.Shape();
            this.shape_49.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,36.9,7,-30.6).s().p("AoCUXMgABgo+IQHdJIi3gBQiuAEi2BeQj8CEh8DjQhSCggVBpQgJA1gDAAQgBAAABgRg");
            this.shape_49.setTransform(365.075,335.8395);
        
            this.shape_50 = new cjs.Shape();
            this.shape_50.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,37,7,-30.5).s().p("AoCURMgABgo3IQHdJIi5gDQitACi7BfQj6CCh4DhQhVClgUBqQgIA0gCAAQgBAAABgWg");
            this.shape_50.setTransform(365.0727,335.7054);
        
            this.shape_51 = new cjs.Shape();
            this.shape_51.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,37.2,7,-30.3).s().p("AoCUMMgABgowIQHdJIi7gGQisAAjBBiQj2B/h1DeQhXCrgTBpQgHAzgCAAQgCAAACgZg");
            this.shape_51.setTransform(365.0543,335.5856);
        
            this.shape_52 = new cjs.Shape();
            this.shape_52.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,37.3,7,-30.2).s().p("AoCUGMAAAgopIQGdJIi9gIQiqgDjGBkQjzB9hzDcQhaCxgRBoQgHAzgCAAQgBAAACgeg");
            this.shape_52.setTransform(365.0404,335.4642);
        
            this.shape_53 = new cjs.Shape();
            this.shape_53.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,37.4,7,-30.1).s().p("AoCUAMAAAgoiIQGdJIi/gKQipgGjMBnQjwB6hwDZQhcC4gPBoQgHAygBAAQgBAAACgjg");
            this.shape_53.setTransform(365.0015,335.3616);
        
            this.shape_54 = new cjs.Shape();
            this.shape_54.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.8,37.5,7.1,-30).s().p("AoCT6MAAAgobIQHdJIjBgMQipgIjRBoQjsB4huDXQheC9gOBoQgGAygBAAQgCAAADgog");
            this.shape_54.setTransform(364.9721,335.2515);
        
            this.shape_55 = new cjs.Shape();
            this.shape_55.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.8,37.6,7.1,-29.9).s().p("AoBT1MAAAgoVIQGdJIjCgOQipgLjWBrQjpB1hqDVQhhDDgNBnQgGAygBAAQgBAAAEgsg");
            this.shape_55.setTransform(364.9426,335.1524);
        
            this.shape_56 = new cjs.Shape();
            this.shape_56.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.8,37.7,7.1,-29.8).s().p("AoBTvMAAAgoOIQGdJIjEgQQiogOjbBuQjmByhoDSQhjDKgLBmIgGAyIADgxg");
            this.shape_56.setTransform(364.9066,335.0563);
        
            this.shape_57 = new cjs.Shape();
            this.shape_57.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.7,37.8,7.2,-29.7).s().p("AoBTpMAAAgoHIQHdJIjHgSQimgQjhBvQjjBwhlDQQhlDQgKBlIgGAyIAEg2g");
            this.shape_57.setTransform(364.8708,334.9566);
        
            this.shape_58 = new cjs.Shape();
            this.shape_58.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.8,37.7,7.1,-29.8).s().p("AoBTvMAAAgoOIQGdJIjEgQQiogNjbBtQjmByhoDTQhjDJgLBmIgGAyIADgxg");
            this.shape_58.setTransform(364.9066,335.0626);
        
            this.shape_59 = new cjs.Shape();
            this.shape_59.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.8,37.6,7.1,-29.9).s().p("AoBT1MAAAgoVIQGdJIjCgNQiogLjWBqQjqB1hqDVQhhDDgNBnQgGAygBAAQgBAAAEgsg");
            this.shape_59.setTransform(364.9426,335.1649);
        
            this.shape_60 = new cjs.Shape();
            this.shape_60.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.8,37.5,7.1,-30).s().p("AoCT7MAAAgocIQHdJIjBgLQipgJjQBpQjtB3huDYQhdC9gPBnQgGAygBAAQgCAAADgng");
            this.shape_60.setTransform(364.975,335.2711);
        
            this.shape_61 = new cjs.Shape();
            this.shape_61.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,37.4,7,-30.1).s().p("AoCUCMAAAgokIQGdJIi+gJQiqgGjLBmQjwB7hxDaQhbC2gQBoQgGAygCAAQgBAAACghg");
            this.shape_61.setTransform(365.0036,335.3841);
        
            this.shape_62 = new cjs.Shape();
            this.shape_62.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,37.2,7,-30.3).s().p("AoCUHMAAAgorIQGdJIi8gHQirgCjFBjQj0B9hzDdQhZCwgSBoQgHA0gCAAQgBAAACgeg");
            this.shape_62.setTransform(365.0404,335.501);
        
            this.shape_63 = new cjs.Shape();
            this.shape_63.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,37.1,7,-30.4).s().p("AoCUNMgABgoyIQHdJIi7gEQirAAjABhQj3CAh2DfQhXCpgTBpQgHA0gCAAQgCAAACgZg");
            this.shape_63.setTransform(365.0629,335.6245);
        
            this.shape_64 = new cjs.Shape();
            this.shape_64.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,37,7,-30.5).s().p("AoCUTMgABgo5IQHdJIi5gDQisADi6BfQj6CCh6DiQhUCjgUBqQgJA0gCAAQgBAAABgUg");
            this.shape_64.setTransform(365.075,335.7559);
        
            this.shape_65 = new cjs.Shape();
            this.shape_65.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,36.9,7,-30.6).s().p("AoCUZMgABgpAIQHdJIi3gBQitAGi0BcQj+CGh9DkQhRCdgWBqQgJA0gCAAQgCAAABgPg");
            this.shape_65.setTransform(365.075,335.8972);
        
            this.shape_66 = new cjs.Shape();
            this.shape_66.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,36.7,7,-30.8).s().p("AoCUeMgABgpHIQHdJIi1ABQiuAJiuBaQkCCIh/DnQhPCWgXBrQgKA2gDAAQgBAAAAgMg");
            this.shape_66.setTransform(365.075,336.051);
        
            this.shape_67 = new cjs.Shape();
            this.shape_67.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,36.5,7,-31).s().p("AoCUkMgABgpPIQHdJIiyAEQiwALioBYQkFCLiDDpQhMCQgZBrQgLA4gDAAQgBAAAAgIg");
            this.shape_67.setTransform(365.075,336.2317);
        
            this.shape_68 = new cjs.Shape();
            this.shape_68.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,36.3,7,-31.2).s().p("AoCUpMgABgpWIQHdJIiwAGQixAOijBVQkICOiFDsQhKCJgaBsQgMA6gEAAQgBAAAAgFg");
            this.shape_68.setTransform(365.075,336.4245);
        
            this.shape_69 = new cjs.Shape();
            this.shape_69.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,36.1,7,-31.4).s().p("AoCUuMgABgpdIQHdJIiuAIQiyARieBTQkLCQiIDvQhHCDgcBsQgNA8gEAAIgBgCg");
            this.shape_69.setTransform(365.075,336.6458);
        
            this.shape_70 = new cjs.Shape();
            this.shape_70.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,35.8,7,-31.7).s().p("AoCUyMgABgpkIQHdJIisAKQizAUiYBQQkOCTiMDyQhEB8gdBtQgPBAgFAAIAAgBg");
            this.shape_70.setTransform(365.075,336.9068);
        
            this.shape_71 = new cjs.Shape();
            this.shape_71.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,35.5,7,-32).s().p("AoD01IQHdJIiqANQizAWiTBOQkSCWiODzQhCB3gfBtQgRBDgEABg");
            this.shape_71.setTransform(365.075,337.225);
        
            this.shape_72 = new cjs.Shape();
            this.shape_72.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,35.2,7,-32.3).s().p("AoD04IQHdJIioAOQi0AZiNBMQkWCYiRD3Qg/BwghBtQgRBBgFAIg");
            this.shape_72.setTransform(365.075,337.575);
        
            this.shape_73 = new cjs.Shape();
            this.shape_73.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,34.8,7,-32.7).s().p("AoD08IQHdJIimARQi1AbiIBKQkYCbiUD5Qg9BpgiBuQgSA/gGAQg");
            this.shape_73.setTransform(365.075,337.95);
        
            this.shape_74 = new cjs.Shape();
            this.shape_74.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,34.5,7,-33).s().p("AoD1AIQHdJIikATQi2AeiCBIQkcCdiXD8Qg6BjgkBuIgZBVg");
            this.shape_74.setTransform(365.075,338.3);
        
            this.shape_75 = new cjs.Shape();
            this.shape_75.graphics.lf(["#393735","rgba(43,41,39,0.737)","rgba(19,18,18,0.329)","rgba(5,5,5,0.082)","rgba(0,0,0,0)"],[0,0.157,0.467,0.757,1],-109.9,34.1,7,-33.4).s().p("AoD1DIQHdJIiiAVQi3Ahh8BEQkgChiaD+Qg3BdglBvQgTA6gIAfg");
            this.shape_75.setTransform(365.075,338.65);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_37}]}).to({state:[{t:this.shape_38}]},1).to({state:[{t:this.shape_39}]},1).to({state:[{t:this.shape_40}]},1).to({state:[{t:this.shape_41}]},1).to({state:[{t:this.shape_42}]},1).to({state:[{t:this.shape_43}]},1).to({state:[{t:this.shape_44}]},1).to({state:[{t:this.shape_45}]},1).to({state:[{t:this.shape_46}]},1).to({state:[{t:this.shape_47}]},1).to({state:[{t:this.shape_48}]},1).to({state:[{t:this.shape_49}]},1).to({state:[{t:this.shape_50}]},1).to({state:[{t:this.shape_51}]},1).to({state:[{t:this.shape_52}]},1).to({state:[{t:this.shape_53}]},1).to({state:[{t:this.shape_54}]},1).to({state:[{t:this.shape_55}]},1).to({state:[{t:this.shape_56}]},1).to({state:[{t:this.shape_57}]},1).to({state:[{t:this.shape_58}]},1).to({state:[{t:this.shape_59}]},1).to({state:[{t:this.shape_60}]},1).to({state:[{t:this.shape_61}]},1).to({state:[{t:this.shape_62}]},1).to({state:[{t:this.shape_63}]},1).to({state:[{t:this.shape_64}]},1).to({state:[{t:this.shape_65}]},1).to({state:[{t:this.shape_66}]},1).to({state:[{t:this.shape_67}]},1).to({state:[{t:this.shape_68}]},1).to({state:[{t:this.shape_69}]},1).to({state:[{t:this.shape_70}]},1).to({state:[{t:this.shape_71}]},1).to({state:[{t:this.shape_72}]},1).to({state:[{t:this.shape_73}]},1).to({state:[{t:this.shape_74}]},1).to({state:[{t:this.shape_75}]},1).to({state:[{t:this.shape_37}]},1).wait(1));
        
            // Nase
            this.shape_76 = new cjs.Shape();
            this.shape_76.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],0,0,0,0,0,222.4).s().p("EgTvAmtQiqhIiCiCQiDiDhHipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQCOCaBNDCQBPDIAADZQAAGMj2E1QjzEwl3BbI47GeQgmALgqAKIgDAAQhkAVhfAAQi/AAivhKg");
            this.shape_76.setTransform(497.5,320.025);
        
            this.shape_77 = new cjs.Shape();
            this.shape_77.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-0.2,0,0,-0.2,0,222.4).s().p("EgTyAmtQiphIiDiCQiCiDhHipQhLivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDWAAQBwAABmAvQBjAtBHBSMAmxAouQCSCcBNDCQBRDHAADeQgBGIj2E0Qj0Ewl7BaI47GeQglALgrAKIgCAAQhkAVhgAAQi/AAivhKg");
            this.shape_77.setTransform(497.7502,320.025);
        
            this.shape_78 = new cjs.Shape();
            this.shape_78.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-0.5,0,0,-0.5,0,222.4).s().p("EgT1AmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQCVCeBPDCQBQDHAADiQAAGDj3E0Qj0EwmABZI46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_78.setTransform(498.025,320.025);
        
            this.shape_79 = new cjs.Shape();
            this.shape_79.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-0.7,0,0,-0.7,0,222.4).s().p("EgT3AmtQiphIiDiCQiCiDhIipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDVAAQBxAABmAvQBiAtBIBSMAmxAouQCaCgBPDBQBRDHAADnQAAF/j4EzQj1EwmDBYI47GeQglALgrAKIgDAAQhjAVhgAAQi/AAivhKg");
            this.shape_79.setTransform(498.275,320.025);
        
            this.shape_80 = new cjs.Shape();
            this.shape_80.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-1,0,0,-1,0,222.4).s().p("EgT6AmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQCdCiBQDBQBSDGgBDsQAAF6j4E0Qj2EvmHBXI46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_80.setTransform(498.5252,320.025);
        
            this.shape_81 = new cjs.Shape();
            this.shape_81.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-1.3,0,0,-1.3,0,222.4).s().p("EgT8AmtQiqhIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQChCkBRDBQBSDGAADwQgBF2j5EzQj2EvmKBWI47GeQgmALgqAKIgDAAQhkAVhfAAQi/AAivhKg");
            this.shape_81.setTransform(498.8002,320.025);
        
            this.shape_82 = new cjs.Shape();
            this.shape_82.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-1.5,0,0,-1.5,0,222.4).s().p("EgT/AmtQiphIiDiCQiCiDhHipQhLivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDWAAQBwAABmAvQBjAtBHBSMAmxAouQCmCmBRDBQBTDFAAD1QgBFyj6EyQj3EvmOBVI47GeQglALgrAKIgCAAQhkAVhgAAQi/AAivhKg");
            this.shape_82.setTransform(499.0502,320.025);
        
            this.shape_83 = new cjs.Shape();
            this.shape_83.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-1.8,0,0,-1.8,0,222.4).s().p("EgUCAmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQCpCoBSDBQBUDFgBD5QgBFtj6EyQj4EvmSBUI46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_83.setTransform(499.3006,320.025);
        
            this.shape_84 = new cjs.Shape();
            this.shape_84.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-2,0,0,-2,0,222.4).s().p("EgUEAmtQiphIiDiCQiCiDhIipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDVAAQBxAABmAvQBiAtBIBSMAmxAouQCuCqBSDBQBUDEAAD+QgBFpj7ExQj5EvmVBTI47GeQglALgrAKIgDAAQhjAVhgAAQi/AAivhKg");
            this.shape_84.setTransform(499.5752,320.025);
        
            this.shape_85 = new cjs.Shape();
            this.shape_85.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-2.3,0,0,-2.3,0,222.4).s().p("EgUHAmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQCxCsBUDBQBUDDgBEDQAAFkj8EyQj6EumZBSI46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_85.setTransform(499.8252,320.025);
        
            this.shape_86 = new cjs.Shape();
            this.shape_86.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-2.6,0,0,-2.6,0,222.4).s().p("EgUJAmtQiqhIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQC2CuBTDBQBVDDAAEHQgBFgj8ExQj7EumcBRI47GeQgmALgqAKIgDAAQhkAVhfAAQi/AAivhKg");
            this.shape_86.setTransform(500.1002,320.025);
        
            this.shape_87 = new cjs.Shape();
            this.shape_87.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-2.8,0,0,-2.8,0,222.4).s().p("EgUMAmtQiphIiDiCQiCiDhIipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDVAAQBxAABmAvQBiAtBIBSMAmxAouQC6CwBUDAQBWDDgBEMQgBFbj9ExQj7EumgBQI47GeQglALgrAKIgDAAQhjAVhgAAQi/AAivhKg");
            this.shape_87.setTransform(500.3506,320.025);
        
            this.shape_88 = new cjs.Shape();
            this.shape_88.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-3.1,0,0,-3.1,0,222.4).s().p("EgUPAmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQC9CyBWDAQBWDDgBEQQgBFXj+EwQj8EumkBPI46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_88.setTransform(500.6006,320.025);
        
            this.shape_89 = new cjs.Shape();
            this.shape_89.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-3.3,0,0,-3.3,0,222.4).s().p("EgURAmtQiphIiDiCQiCiDhIipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDVAAQBxAABmAvQBiAtBIBSMAmxAouQDCC0BWDAQBWDCAAEVQgBFSj/EwQj9EumnBOI47GeQglALgrAKIgDAAQhjAVhgAAQi/AAivhKg");
            this.shape_89.setTransform(500.8751,320.025);
        
            this.shape_90 = new cjs.Shape();
            this.shape_90.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-3.6,0,0,-3.6,0,222.4).s().p("EgUUAmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQDFC2BXDAQBXDBgBEaQgBFOj/EwQj+EtmrBNI46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_90.setTransform(501.1256,320.025);
        
            this.shape_91 = new cjs.Shape();
            this.shape_91.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-3.8,0,0,-3.8,0,222.4).s().p("EgUWAmtQiphIiDiCQiCiDhIipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDVAAQBxAABmAvQBiAtBIBSMAmxAouQDKC4BXDAQBYDBgBEeQgBFKkAEvQj+EumvBLI47GeQglALgrAKIgDAAQhjAVhgAAQi/AAivhKg");
            this.shape_91.setTransform(501.3763,320.025);
        
            this.shape_92 = new cjs.Shape();
            this.shape_92.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-4.1,0,0,-4.1,0,222.4).s().p("EgUZAmtQiphIiDiCQiCiDhIipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDVAAQBxAABmAvQBiAtBIBSMAmxAouQDOC6BYDAQBYDAgBEiQgBFGkAEvQkAEumyBKI47GeQglALgrAKIgDAAQhjAVhgAAQi/AAivhKg");
            this.shape_92.setTransform(501.6506,320.025);
        
            this.shape_93 = new cjs.Shape();
            this.shape_93.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-4.4,0,0,-4.4,0,222.4).s().p("EgUcAmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQDRC8BZDAQBZDAgBEmQgCFCkAEuQkBEum2BJI46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_93.setTransform(501.9012,320.025);
        
            this.shape_94 = new cjs.Shape();
            this.shape_94.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-4.6,0,0,-4.6,0,222.4).s().p("EgUeAmtQiphIiDiCQiCiDhIipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDVAAQBxAABmAvQBiAtBIBSMAmxAouQDWC+BZC/QBaDAgCErQgBE9kBEuQkCEum5BII47GeQglALgrAKIgDAAQhjAVhgAAQi/AAivhKg");
            this.shape_94.setTransform(502.1512,320.025);
        
            this.shape_95 = new cjs.Shape();
            this.shape_95.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-4.9,0,0,-4.9,0,222.4).s().p("EgUhAmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQDZDABbC/QBZC/gBEwQgBE5kCEuQkCEtm+BHI46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_95.setTransform(502.4256,320.025);
        
            this.shape_96 = new cjs.Shape();
            this.shape_96.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-5.1,0,0,-5.1,0,222.4).s().p("EgUjAmtQiphIiDiCQiCiDhIipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDVAAQBxAABmAvQBiAtBIBSMAmxAouQDeDCBaC/QBbC/gBE0QgCE0kDEtQkCEunBBGI47GeQglALgrAKIgDAAQhjAVhgAAQi/AAivhKg");
            this.shape_96.setTransform(502.6765,320.025);
        
            this.shape_97 = new cjs.Shape();
            this.shape_97.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-4.9,0,0,-4.9,0,222.4).s().p("EgUhAmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQDZDABaC/QBaC/gBEwQgCE5kBEtQkDEum9BHI46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_97.setTransform(502.4012,320.025);
        
            this.shape_98 = new cjs.Shape();
            this.shape_98.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-4.6,0,0,-4.6,0,222.4).s().p("EgUeAmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQDVC+BZC/QBaDAgCErQgBE9kBEuQkBEum6BII46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_98.setTransform(502.1262,320.025);
        
            this.shape_99 = new cjs.Shape();
            this.shape_99.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-4.3,0,0,-4.3,0,222.4).s().p("EgUbAmtQiphIiDiCQiCiDhIipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDVAAQBxAABmAvQBiAtBIBSMAmxAouQDRC8BZC/QBZDAgCEmQgBFCkAEvQkBEtm1BKI47GeQglALgrAKIgDAAQhjAVhgAAQi/AAivhKg");
            this.shape_99.setTransform(501.8512,320.025);
        
            this.shape_100 = new cjs.Shape();
            this.shape_100.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-4,0,0,-4,0,222.4).s().p("EgUYAmtQiphIiDiCQiCiDhIipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDVAAQBxAABmAvQBiAtBIBSMAmxAouQDNC6BYC/QBYDBgBEhQgBFHkAEvQkAEtmxBLI47GeQglALgrAKIgDAAQhjAVhgAAQi/AAivhKg");
            this.shape_100.setTransform(501.5763,320.025);
        
            this.shape_101 = new cjs.Shape();
            this.shape_101.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-3.8,0,0,-3.8,0,222.4).s().p("EgUWAmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQDIC4BYC/QBXDBgBEdQgBFLj/EvQj/EumuBMI46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_101.setTransform(501.3256,320.025);
        
            this.shape_102 = new cjs.Shape();
            this.shape_102.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-3.5,0,0,-3.5,0,222.4).s().p("EgUTAmtQiphIiDiCQiCiDhIipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDVAAQBxAABmAvQBiAtBIBSMAmxAouQDEC2BXC/QBXDCgBEYQgBFPj/EwQj9EumqBNI47GeQglALgrAKIgDAAQhjAVhgAAQi/AAivhKg");
            this.shape_102.setTransform(501.0506,320.025);
        
            this.shape_103 = new cjs.Shape();
            this.shape_103.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-3.2,0,0,-3.2,0,222.4).s().p("EgUQAmtQiphIiDiCQiCiDhIipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDVAAQBxAABmAvQBiAtBIBSMAmxAouQDAC0BWC/QBXDCgBETQgBFVj+EwQj9EummBOI47GeQglALgrAKIgDAAQhjAVhgAAQi/AAivhKg");
            this.shape_103.setTransform(500.7756,320.025);
        
            this.shape_104 = new cjs.Shape();
            this.shape_104.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-3,0,0,-3,0,222.4).s().p("EgUOAmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQC8CxBVDAQBWDDgBEOQgBFZj9ExQj8EumjBPI46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_104.setTransform(500.5006,320.025);
        
            this.shape_105 = new cjs.Shape();
            this.shape_105.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-2.7,0,0,-2.7,0,222.4).s().p("EgULAmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQC3CvBVDAQBVDDgBEKQgBFej8EwQj7EvmfBQI46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_105.setTransform(500.2256,320.025);
        
            this.shape_106 = new cjs.Shape();
            this.shape_106.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-2.4,0,0,-2.4,0,222.4).s().p("EgUIAmtQiphIiDiCQiCiDhHipQhLivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDWAAQBwAABmAvQBjAtBHBSMAmxAouQC0CtBTDBQBVDDAAEFQgBFij8ExQj6EvmbBRI47GeQglALgrAKIgCAAQhkAVhgAAQi/AAivhKg");
            this.shape_106.setTransform(499.9502,320.025);
        
            this.shape_107 = new cjs.Shape();
            this.shape_107.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-2.1,0,0,-2.1,0,222.4).s().p("EgUFAmtQiphIiDiCQiCiDhIipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDVAAQBxAABmAvQBiAtBIBSMAmxAouQCvCrBTDBQBUDEAAD/QgBFnj7EyQj6EvmWBSI47GeQglALgrAKIgDAAQhjAVhgAAQi/AAivhKg");
            this.shape_107.setTransform(499.6752,320.025);
        
            this.shape_108 = new cjs.Shape();
            this.shape_108.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-1.9,0,0,-1.9,0,222.4).s().p("EgUCAmtQiqhIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQCrCpBSDBQBTDEAAD7QgBFsj6EyQj5EumSBUI47GeQgmALgqAKIgDAAQhkAVhfAAQi/AAivhKg");
            this.shape_108.setTransform(499.4002,320.025);
        
            this.shape_109 = new cjs.Shape();
            this.shape_109.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-1.6,0,0,-1.6,0,222.4).s().p("EgUAAmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQCnCnBRDBQBTDFgBD2QAAFwj6EyQj4EvmPBVI46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_109.setTransform(499.1252,320.025);
        
            this.shape_110 = new cjs.Shape();
            this.shape_110.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-1.3,0,0,-1.3,0,222.4).s().p("EgT9AmtQiphIiDiCQiCiDhHipQhLivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDWAAQBwAABmAvQBjAtBHBSMAmxAouQCjClBQDBQBTDFgBDyQAAF0j5EzQj3EvmLBWI47GeQglALgrAKIgCAAQhkAVhgAAQi/AAivhKg");
            this.shape_110.setTransform(498.8502,320.025);
        
            this.shape_111 = new cjs.Shape();
            this.shape_111.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-1.1,0,0,-1.1,0,222.4).s().p("EgT6AmtQiqhIiCiCQiDiDhHipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQCfCjBPDBQBSDGAADsQgBF6j4EzQj2EvmHBXI47GeQgmALgqAKIgDAAQhkAVhfAAQi/AAivhKg");
            this.shape_111.setTransform(498.6,320.025);
        
            this.shape_112 = new cjs.Shape();
            this.shape_112.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-0.8,0,0,-0.8,0,222.4).s().p("EgT4AmtQiphIiCiCQiDiDhHipQhKivAAi/IAAgIIAAAAMAAAg40QAAjVCXiXQCWiXDWAAQBxAABmAvQBiAtBIBSMAmxAouQCaChBPDBQBRDGAADoQAAF+j4E0Qj1EvmEBYI46GeQgmALgqAKIgDAAQhkAVhfAAQjAAAivhKg");
            this.shape_112.setTransform(498.325,320.025);
        
            this.shape_113 = new cjs.Shape();
            this.shape_113.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-0.5,0,0,-0.5,0,222.4).s().p("EgT1AmtQiphIiCiCQiDiDhHipQhLivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDWAAQBxAABlAvQBjAtBHBSMAmxAouQCWCeBPDCQBQDHAADjQAAGDj3EzQj1Ewl/BZI46GeQgmALgrAKIgCAAQhkAVhgAAQi/AAivhKg");
            this.shape_113.setTransform(498.05,320.025);
        
            this.shape_114 = new cjs.Shape();
            this.shape_114.graphics.rf(["#FFFFFF","#EEEDEB","#C2BEB9","#C0BCB7"],[0,0.322,0.976,1],-0.2,0,0,-0.2,0,222.4).s().p("EgTyAmtQiphIiDiCQiCiDhIipQhKivAAi/IABgIIgBAAMAAAg40QAAjVCXiXQCXiXDVAAQBxAABmAvQBiAtBIBSMAmxAouQCSCcBODCQBQDHAADfQAAGHj3E0QjzEwl8BaI47GeQglALgrAKIgDAAQhjAVhgAAQi/AAivhKg");
            this.shape_114.setTransform(497.775,320.025);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_76}]}).to({state:[{t:this.shape_77}]},1).to({state:[{t:this.shape_78}]},1).to({state:[{t:this.shape_79}]},1).to({state:[{t:this.shape_80}]},1).to({state:[{t:this.shape_81}]},1).to({state:[{t:this.shape_82}]},1).to({state:[{t:this.shape_83}]},1).to({state:[{t:this.shape_84}]},1).to({state:[{t:this.shape_85}]},1).to({state:[{t:this.shape_86}]},1).to({state:[{t:this.shape_87}]},1).to({state:[{t:this.shape_88}]},1).to({state:[{t:this.shape_89}]},1).to({state:[{t:this.shape_90}]},1).to({state:[{t:this.shape_91}]},1).to({state:[{t:this.shape_92}]},1).to({state:[{t:this.shape_93}]},1).to({state:[{t:this.shape_94}]},1).to({state:[{t:this.shape_95}]},1).to({state:[{t:this.shape_96}]},1).to({state:[{t:this.shape_97}]},1).to({state:[{t:this.shape_98}]},1).to({state:[{t:this.shape_99}]},1).to({state:[{t:this.shape_100}]},1).to({state:[{t:this.shape_101}]},1).to({state:[{t:this.shape_102}]},1).to({state:[{t:this.shape_103}]},1).to({state:[{t:this.shape_104}]},1).to({state:[{t:this.shape_105}]},1).to({state:[{t:this.shape_106}]},1).to({state:[{t:this.shape_107}]},1).to({state:[{t:this.shape_108}]},1).to({state:[{t:this.shape_109}]},1).to({state:[{t:this.shape_110}]},1).to({state:[{t:this.shape_111}]},1).to({state:[{t:this.shape_112}]},1).to({state:[{t:this.shape_113}]},1).to({state:[{t:this.shape_114}]},1).to({state:[{t:this.shape_76}]},1).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = p = new lib.AnMovieClip();
        p.nominalBounds = new cjs.Rectangle(793.1,385,-101.20000000000005,190.10000000000002);
        // library properties:
        lib.properties = {
            id: 'CEF820B6B8A948708F58E96F12CA2B54',
            width: 960,
            height: 640,
            fps: 24,
            color: "#FFFFFF",
            opacity: 1.00,
            manifest: [],
            preloads: []
        };
        
        
        
        // bootstrap callback support:
        
        (lib.Stage = function(canvas) {
            createjs.Stage.call(this, canvas);
        }).prototype = p = new createjs.Stage();
        
        p.setAutoPlay = function(autoPlay) {
            this.tickEnabled = autoPlay;
        }
        p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
        p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
        p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
        p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }
        
        p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }
        
        an.bootcompsLoaded = an.bootcompsLoaded || [];
        if(!an.bootstrapListeners) {
            an.bootstrapListeners=[];
        }
        
        an.bootstrapCallback=function(fnCallback) {
            an.bootstrapListeners.push(fnCallback);
            if(an.bootcompsLoaded.length > 0) {
                for(var i=0; i<an.bootcompsLoaded.length; ++i) {
                    fnCallback(an.bootcompsLoaded[i]);
                }
            }
        };
        
        an.compositions = an.compositions || {};
        an.compositions['CEF820B6B8A948708F58E96F12CA2B54'] = {
            getStage: function() { return exportRoot.stage; },
            getLibrary: function() { return lib; },
            getSpriteSheet: function() { return ss; },
            getImages: function() { return img; }
        };
        
        an.compositionLoaded = function(id) {
            an.bootcompsLoaded.push(id);
            for(var j=0; j<an.bootstrapListeners.length; j++) {
                an.bootstrapListeners[j](id);
            }
        }
        
        an.getComposition = function(id) {
            return an.compositions[id];
        }
        
        
        an.makeResponsive = function(isResp, respDim, isScale, scaleType, domContainers) {		
            var lastW, lastH, lastS=1;		
            window.addEventListener('resize', resizeCanvas);		
            resizeCanvas();		
            function resizeCanvas() {			
                var w = lib.properties.width, h = lib.properties.height;			
                var iw = window.innerWidth, ih=window.innerHeight;			
                var pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;			
                if(isResp) {                
                    if((respDim=='width'&&lastW==iw) || (respDim=='height'&&lastH==ih)) {                    
                        sRatio = lastS;                
                    }				
                    else if(!isScale) {					
                        if(iw<w || ih<h)						
                            sRatio = Math.min(xRatio, yRatio);				
                    }				
                    else if(scaleType==1) {					
                        sRatio = Math.min(xRatio, yRatio);				
                    }				
                    else if(scaleType==2) {					
                        sRatio = Math.max(xRatio, yRatio);				
                    }			
                }
                domContainers[0].width = w * pRatio * sRatio;			
                domContainers[0].height = h * pRatio * sRatio;
                domContainers.forEach(function(container) {				
                    container.style.width = w * sRatio + 'px';				
                    container.style.height = h * sRatio + 'px';			
                });
                stage.scaleX = pRatio*sRatio;			
                stage.scaleY = pRatio*sRatio;
                lastW = iw; lastH = ih; lastS = sRatio;            
                stage.tickOnUpdate = false;            
                stage.update();            
                stage.tickOnUpdate = true;		
            }
        }
        an.handleSoundStreamOnTick = function(event) {
            if(!event.paused){
                var stageChild = stage.getChildAt(0);
                if(!stageChild.paused){
                    stageChild.syncStreamSounds();
                }
            }
        }
        
        
        })(createjs = createjs||{}, AdobeAn = AdobeAn||{});
        var createjs, AdobeAn;

 }else if($("#animation_container").hasClass('heart')){

    (function (cjs, an) {

        var p; // shortcut to reference prototypes
        var lib={};var ss={};var img={};
        lib.ssMetadata = [];
        
        
        (lib.AnMovieClip = function(){
            this.actionFrames = [];
            this.ignorePause = false;
            this.gotoAndPlay = function(positionOrLabel){
                cjs.MovieClip.prototype.gotoAndPlay.call(this,positionOrLabel);
            }
            this.play = function(){
                cjs.MovieClip.prototype.play.call(this);
            }
            this.gotoAndStop = function(positionOrLabel){
                cjs.MovieClip.prototype.gotoAndStop.call(this,positionOrLabel);
            }
            this.stop = function(){
                cjs.MovieClip.prototype.stop.call(this);
            }
        }).prototype = p = new cjs.MovieClip();
        // symbols:
        // helper functions:
        
        function mc_symbol_clone() {
            var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop, this.reversed));
            clone.gotoAndStop(this.currentFrame);
            clone.paused = this.paused;
            clone.framerate = this.framerate;
            return clone;
        }
        
        function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
            var prototype = cjs.extend(symbol, cjs.MovieClip);
            prototype.clone = mc_symbol_clone;
            prototype.nominalBounds = nominalBounds;
            prototype.frameBounds = frameBounds;
            return prototype;
            }
        
        
        (lib.Symbol1 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // FlashAICB
            this.shape = new cjs.Shape();
            this.shape.graphics.lf(["rgba(255,255,255,0)","rgba(255,255,255,0.698)"],[0.192,1],-90.7,-116.7,26.8,52.7).s().p("AviMKQg7lyBOlSQBMlHDAj0QDCj3EXh1QEkh6FWAoQB8AOBuAkQB5AnBKA5QBrBRAwBnQAuBggMBlQgNBihCBSQhDBUhvAyQhjAtivAQIidAHQodhMk0DwQjoC2hYFiQhGEZgeAOIgDABQgXAAgdi0g");
            this.shape.setTransform(-168.9091,-182.9342,0.8434,0.8434);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.lf(["rgba(202,59,67,0.4)","rgba(202,59,67,0)"],[0.204,1],-126.2,-8.9,126.3,-8.9).s().p("AviGtIkMpbQDii0EOhhQEYhkEsAAQHAAAGFDZQF5DSDrFqQjfiQjjhRQkIhflSgkQlQgkjjG7QiHEHjJAAQiJAAiph7g");
            this.shape_1.setTransform(144.275,-209.5541);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.rf(["#FFFFFF","#F91C41"],[0,0.757],-160.6,-205.3,0,-6.6,-51.1,396.8).s().p("EgMRAlZQjfjKjujyIjCjKQsSsSmIssQlprrAArGIAAAAIAAgFQAAlfCHlAQCDk1DwjwQDujuE1iDQFBiIFeABQG6gBGBDTQF0DMDrFiQDii1EPhgQEYhkEsgBQFeAAFBCIQE1CDDvDuQDvDvCDE1QCHFBAAFfIAAArIAAAAQAAK/k2K/QlLLvqBKBQkFEXk9EZQp4IvkSAAQkfABrIqGg");
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.Symbol1, new cjs.Rectangle(-298,-303.8,596,607.7), null);
        
        
        (lib.Sterne = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.rf(["rgba(255,255,255,0)","rgba(241,211,215,0)"],[0,0.831],-0.1,0.1,0,-0.1,0.1,11.2).s().p("AgMAKIhLAiIBBgyIhLgnIBRAYIAEhLIATBKIBSgdIhKAwIBTA4IhdgoIgSBUg");
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.rf(["rgba(255,255,255,0.502)","rgba(241,211,215,0)"],[0,0.831],-0.1,0,0,-0.1,0,11.2).s().p("AgMAKIhLAiIBAgzIhLgmIBSAYIAEhLIATBKIBSgdIhKAwIBUA4IhegpIgTBVg");
            this.shape_1.setTransform(0.025,0.025);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.rf(["#FFFFFF","rgba(241,211,215,0)"],[0,0.831],-0.1,0.1,0,-0.1,0.1,11.3).s().p("AgMAKIhLAiIBBgyIhMgnIBSAXIADhKIAUBKIBSgdIhKAwIBUA4IhegoIgSBUg");
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.rf(["rgba(255,255,255,0.502)","rgba(241,211,215,0)"],[0,0.831],-0.1,0.1,0,-0.1,0.1,11.2).s().p("AgMAKIhLAiIBBgyIhLgmIBRAXIAEhLIATBKIBSgdIhKAwIBTA4IhdgoIgSBUg");
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.rf(["rgba(255,255,255,0)","rgba(241,211,215,0)"],[0,0.831],-0.5,0.1,0,-0.5,0.1,30.1).s().p("AghAbIjKBcICuiKIjLhmIDbA+IALjIIA2DHIDbhPIjFCCIDfCYIj6huIg1Djg");
            this.shape_4.setTransform(0,0.0093,0.3704,0.3704);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape}]}).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_4}]},1).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-9.9,-9.7,19.8,19.5);
        
        
        // stage content:
        (lib.herz = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_4
            this.instance = new lib.Sterne();
            this.instance.setTransform(391.8,666.3,1.4531,1.4531);
            this.instance._off = true;
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(39).to({_off:false},0).wait(1));
        
            // Arterie_oben
            this.shape = new cjs.Shape();
            this.shape.graphics.lf(["#7B7871","#8D8A84","#BBB9B5","#FFFFFF"],[0.169,0.278,0.502,0.796],71.7,79.6,-185.5,-206).s().p("A8AZPQjBjAAAkRIAArCQAAnJCymiQCqmUE5k4QE4k4GUirQGiixHIAAIQmAAQERAADADAQDBDBAAEQQAAEQjBDBQjADBkRAAIwmAAQmDgBkTETQkSERAAGFIAALCQAAERjBDAQjADBkRAAQkPAAjBjBg");
            this.shape.setTransform(858.275,270.9);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.lf(["#7B7871","#8D8A84","#BBB9B5","#FFFFFF"],[0.169,0.278,0.502,0.796],49.3,54.8,-127.7,-141.8).s().p("AzRRYQiFiFAAi7IAAnnQAAk5B6kgQB2kWDXjXQDWjXEXh1QEgh6E5AAILbAAQC7AACFCEQCFCFgBC7QABC7iFCFQiFCEi7AAIrbAAQkKAAi9C9Qi9C8AAELIAAHnQAAC7iFCFQiECEi7AAQi8AAiEiEg");
            this.shape_1.setTransform(858.2883,270.8988,1.4531,1.4531);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape}]}).to({state:[{t:this.shape_1}]},23).to({state:[]},1).wait(16));
        
            // Mask (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            var mask_graphics_0 = new cjs.Graphics().p("EAC0BGsQlDkllalgIkaklQx3x3o5ycQoMw+AAwGIAAAAIAAgIQAAn+DFnSQC+nBFblcQFblaHCi+QHRjFH+AAQKAAAIvEyQIeEoFXICQFHkGGKiMQGXiSG0AAQH9AAHSDFQHCC+FaFbQFbFbC+HBQDFHSAAH+IAAA+IAAAAQAAP9nCP/QniREuiOiQl8GXnMGXQuXMtmOAAQmiAAwLuqg");
            var mask_graphics_1 = new cjs.Graphics().p("EgR6A2kQlFknlclhIkbknQx7x7o8ygQoPxCAAwMIABAAIgBgIQAAoADGnUQC/nDFdldQFclcHEi/QHTjGIAAAQKEAAIxEzQIgEqFYIEQFJkHGLiNQGZiSG2AAQH/AAHUDFQHEDAFcFcQFcFdC/HCQDGHUAAIAIgBA/IABAAQAAQDnFQBQnjRJumOmQl+GYnOGZQubMxmQAAQmiAAwQuug");
            var mask_graphics_2 = new cjs.Graphics().p("EgR/A2zQlHkoldljIkckoQyByAo+ylQoQxHAAwQIABAAIgBgIQAAoDDHnWQC/nEFeleQFeleHFjAQHWjHICAAQKHAAIzE1QIjErFZIGQFKkIGNiNQGbiUG3AAQICAAHWDHQHFDAFeFdQFdFfDAHFQDHHVAAICIgBA/IABAAQAAQInGQFQnmRNuqOqQl/GbnQGaQufM1mSAAQmkAAwUuyg");
            var mask_graphics_3 = new cjs.Graphics().p("EgSEA3BQlIkplelkIkdkpQyGyFpAyqQoTxMAAwUIAAAAIAAgIQAAoFDHnYQDBnGFglgQFflfHHjBQHYjHIEAAQKJAAI2E2QIlEsFaIJQFMkKGOiOQGdiUG5AAQIEAAHYDHQHHDBFfFfQFfFgDBHHQDHHXAAIFIAAA/IAAAAQAAQLnIQLQnnRRuuOuQmBGcnSGcQujM4mTAAQmmAAwZu2g");
            var mask_graphics_4 = new cjs.Graphics().p("EgSJA3QQlJkqlglmIkekqQyLyLpCyvQoWxPAAwZIABAAIgBgIQAAoHDJnaQDBnJFhlgQFhlhHJjCQHajIIGAAQKMAAI4E3QInEuFcILQFOkLGPiPQGfiUG7AAQIGAAHaDIQHJDCFhFgQFgFiDCHIQDIHaAAIGIgBBAIABAAQAAQPnKQPQnqRWuyOyQmCGenUGdQunM8mVAAQmoAAwdu6g");
            var mask_graphics_5 = new cjs.Graphics().p("EgSNA3fQlLkslilnIkfkrQyPyQpFy0QoYxUAAwdIAAAAIAAgIQAAoJDJncQDCnLFjliQFiliHLjCQHcjJIIAAQKPAAI7E4QIpEvFdINQFPkMGRiPQGhiWG9AAQIIAAHcDKQHLDCFiFiQFiFjDCHKQDJHcAAIJIAAA/IAAAAQAAQUnMQTQnsRbu1O2QmEGfnWGgQurM+mWAAQmqAAwhu9g");
            var mask_graphics_6 = new cjs.Graphics().p("EgSSA3tQlNksljlpIkhktQyTyUpIy5QoZxZAAwhIAAAAIAAgIQAAoLDKneQDCnNFkljQFklkHNjDQHejKIKAAQKSAAI9E6QIsEwFfIPQFQkNGTiQQGiiWG/AAQIJAAHeDKQHNDEFkFjQFjFkDEHNQDJHdAAILIgBBAIABAAQAAQYnOQXQnuRgu5O5QmGGinYGhQuuNCmZAAQmrAAwlvCg");
            var mask_graphics_7 = new cjs.Graphics().p("EgSXA38QlOkulklqIkikuQyZyZpKy+QocxdAAwmIABAAIgBgIQAAoNDLngQDEnPFmllQFkllHQjEQHfjLIMAAQKVAAI/E7QIuEyFhIRQFRkOGUiQQGkiXHBAAQINAAHfDLQHQDEFkFlQFmFmDDHOQDLHfAAINIgBBBIABAAQAAQcnQQcQnwRku+O9QmHGjnaGjQuyNGmaAAQmtAAwqvGg");
            var mask_graphics_8 = new cjs.Graphics().p("EgScA4LQlPkwlmlrIkjkvQydyepNzDQoexiAAwqIAAAAIAAgJQAAoPDLnhQDFnRFnlnQFnlmHRjFQHhjLIPAAQKXAAJCE8QIwEyFiIUQFSkPGXiRQGmiXHCAAQIPAAHhDLQHRDFFnFnQFmFnDFHQQDLHhAAIQIAABAIAAAAQAAQhnSQgQnxRpvCPBQmJGlncGkQu2NJmbAAQmvAAwvvJg");
            var mask_graphics_9 = new cjs.Graphics().p("EgShA4ZQlRkwlmltIklkxQyiyipPzIQogxngBwuIABAAIgBgJQAAoRDMnkQDHnSFoloQFoloHSjGQHkjMIRAAQKZAAJFE+QIzEzFjIWQFTkQGZiSQGoiXHDAAQISAAHjDMQHTDGFoFnQFnFpDGHSQDMHkAAIRIgBBBIABAAQAAQlnUQkQnzRtvGPGQmKGmneGmQu6NNmdAAQmxAAwzvOg");
            var mask_graphics_10 = new cjs.Graphics().p("EgSlA4oQlSkylpluIkmkyQynynpSzNQoixrAAwzIAAAAIAAgJQAAoTDNnmQDHnUFplqQFqlpHVjGQHljOITAAQKdAAJGE/QI1E1FlIYQFVkRGaiSQGqiZHFAAQITAAHmDNQHUDHFqFpQFqFqDGHUQDNHmAAITIgBBBIABAAQAAQqnWQpQn1RxvKPKQmNGonfGoQu+NQmfAAQmyAAw3vSg");
            var mask_graphics_11 = new cjs.Graphics().p("EgSrA43QlTkzlqlwIknkzQysyspUzSQokxwgBw3IABAAIgBgJQAAoVDOnoQDInWFrlrQFrlrHWjHQHojOIVAAQKgAAJIFAQI3E2FnIaQFWkSGciTQGriZHIAAQIVAAHoDOQHWDIFrFqQFrFsDIHWQDNHnAAIWIgBBBIABAAQAAQunYQtQn3R2vOPNQmOGqnhGqQvCNTmhAAQm0AAw8vVg");
            var mask_graphics_12 = new cjs.Graphics().p("EgSvA5FQlVk0lrlxIkok0QyxyxpWzXQoox0AAw8IABAAIgBgJQAAoXDPnqQDJnYFsltQFtlsHYjIQHqjPIXAAQKiAAJLFBQI5E4FoIdQFYkUGdiUQGtiZHKAAQIXAAHqDPQHZDIFsFsQFsFtDIHYQDPHpAAIYIgBBCIABAAQAAQxnaQyQn6R7vRPRQmPGsnkGrQvGNXmiAAQm2AAxAvag");
            var mask_graphics_13 = new cjs.Graphics().p("EgS0A5UQlXk1ltlzIkpk1Qy1y2pZzcQopx5AAxAIAAAAIAAgJQAAoaDPnrQDJnaFuluQFuluHbjJQHsjQIYAAQKmAAJNFDQI8E5FqIfQFYkVGgiUQGuibHMAAQIZAAHrDQQHbDJFuFuQFuFuDIHaQDQHsAAIZIgBBCIABAAQAAQ2nbQ2Qn9SAvVPVQmRGtnlGtQvKNbmkAAQm4AAxEveg");
            var mask_graphics_14 = new cjs.Graphics().p("EgAPBH4QlYk3lul0Ikrk3Qy6y7pbzhQosx9AAxFIABAAIgBgIQAAocDQnuQDKncFwlvQFwlvHcjKQHtjRIcAAQKnAAJPFEQI+E6FsIhQFbkVGgiVQGwibHOAAQIcAAHtDRQHdDKFuFuQFwFwDJHcQDRHtAAIcIgBBCIABAAQAAQ6ndQ7Qn/SEvZPZQmSGvnoGvQvNNemmAAQm7AAxHvhg");
            var mask_graphics_19 = new cjs.Graphics().p("EAXhAgSQjAjBAAkQIAArDQAAnJCxmiQCrmTE4k4QE4k5GUiqQGjiyHJAAIQmAAQEQAADBDBQDADBAAEPQAAERjADAQjBDBkQAAIwmAAQmEAAkTERQkTETAAGEIAALDQAAEQjADBQjBDBkQAAQkQAAjBjBg");
        
            this.timeline.addTween(cjs.Tween.get(mask).to({graphics:mask_graphics_0,x:565.2293,y:546.2297}).wait(1).to({graphics:mask_graphics_1,x:697.425,y:650.925}).wait(1).to({graphics:mask_graphics_2,x:697.45,y:650.925}).wait(1).to({graphics:mask_graphics_3,x:697.425,y:650.925}).wait(1).to({graphics:mask_graphics_4,x:697.425,y:650.925}).wait(1).to({graphics:mask_graphics_5,x:697.425,y:650.925}).wait(1).to({graphics:mask_graphics_6,x:697.45,y:650.925}).wait(1).to({graphics:mask_graphics_7,x:697.45,y:650.925}).wait(1).to({graphics:mask_graphics_8,x:697.425,y:650.925}).wait(1).to({graphics:mask_graphics_9,x:697.45,y:650.925}).wait(1).to({graphics:mask_graphics_10,x:697.45,y:650.925}).wait(1).to({graphics:mask_graphics_11,x:697.45,y:650.925}).wait(1).to({graphics:mask_graphics_12,x:697.425,y:650.925}).wait(1).to({graphics:mask_graphics_13,x:697.45,y:650.925}).wait(1).to({graphics:mask_graphics_14,x:578.0895,y:559.2715}).wait(5).to({graphics:mask_graphics_19,x:528.4289,y:225.852}).wait(21));
        
            // Shine
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.rf(["rgba(255,255,255,0.8)","rgba(255,255,255,0)"],[0,1],0,0,0,0,0,274.1).s().p("EgBZAqaQwrgfr7r8QsbsaAAxlQAAxkMbsbQMcsbRjAAQL3AAJiFrQElCuECECQLELFBNPKQAKB3AAB5QAARlsbMaQqVKWt4BvQi0AXi/AAIhZgBg");
            this.shape_2.setTransform(-10.8258,11.0438,1.4531,1.4531);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.rf(["rgba(255,255,255,0.737)","rgba(255,255,255,0)"],[0,1],0,-0.9,0,0,-0.9,398.3).s().p("EgHPA7OQ2UhwvvwjQwWxJAA36QAA5iSDyEQRQxPYKghQQHgTNRHNQH+DeG2GKQO7OlCRUAQCcEnAAFoQAAZhyESDQvAPB0JCjQkHAgkVAAQkFAAjKiSg");
            this.shape_3.setTransform(181.875,144.8874);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.rf(["rgba(255,255,255,0.675)","rgba(255,255,255,0)"],[0,1],0,-1.7,0,0,-1.7,398.3).s().p("EgMcA42Q0bi0uJvxQuowPAA2SQAA5iSDyEQQbwbWzhDQO/gmMsGMQJSC+H3GdQNvNDCyR/QErGjAAIeQAAZhyESDQvAPA0JCjQkHAhkVAAQnJAAlTkig");
            this.shape_4.setTransform(374.625,278.6585);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.rf(["rgba(255,255,255,0.612)","rgba(255,255,255,0)"],[0,1],0,-2.4,0,0,-2.4,398.3).s().p("EgRoA2fQyjj4siu/Qs7vUAA0rQAA5iSDyEQPnvnVbhkQN3g4MHFJQKoCeI2GxQMkLiDSP+QG6IdAALUQAAZhyESDQvAPB0JCjQkHAgkVAAQqNAAnbmyg");
            this.shape_5.setTransform(567.325,412.2865);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.rf(["rgba(255,255,255,0.549)","rgba(255,255,255,0)"],[0,1],0,-2.9,0,0,-2.9,398.3).s().p("EgW0A0JQwrk7q7uNQrOuaAAzDQAA5iSDyEQOzuzUEiFQMvhMLiEIQL8B+J2HEQLYKBDzN8QJJKZAAOKQAAZhyESDQvAPA0JCjQkHAhkVAAQtRAApjpDg");
            this.shape_6.setTransform(760.025,545.7593);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.rf(["rgba(255,255,255,0.486)","rgba(255,255,255,0)"],[0,1],0,-3.2,0,0,-3.2,398.3).s().p("EgcAAx2Quyl+pVtaQphthAAxcQAA5iSDyEQN/t+SsimQLnhfK9DGQNRBfK2HWQKMIgEVL7QLXMTAARAQAAZhyESDQvAPB0JCjQkHAgkVAAQwVAArrrTg");
            this.shape_7.setTransform(952.725,678.9349);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.rf(["rgba(255,255,255,0.424)","rgba(255,255,255,0)"],[0,1],0,-3,0,0,-3,398.3).s().p("EghNAvoQs5nCnusoQn0snAAv0QAA5iSDyEQNKtKRVjIQKghyKXCEQOmBAL2HpQJAG/E2J6QNmOOAAT2QAAZhyESDQvAPA0JCjQkHAhkVAAQzZAAt0tjg");
            this.shape_8.setTransform(1145.475,811.7335);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.rf(["rgba(255,255,255,0.361)","rgba(255,255,255,0)"],[0,1],0,-2.2,0,0,-2.2,398.3).s().p("EgmZAtgQrBoFmHr3QmHrsAAuNQAA5iSDyEQMWsVP9jqQJYiEJyBCQP7AfM2H9QH1FeFWH4QP1QJAAWsQAAZhyESDQvAPB0JCjQkHAgkVAAQ2dAAv8vzg");
            this.shape_9.setTransform(1338.175,943.8278);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.rf(["rgba(255,255,255,0.298)","rgba(255,255,255,0)"],[0,1],0,0,0,0,0,274.1).s().p("A9/d/QmTmSjGnnQjCnbAAorQAAxkMbsbQH8n8KCi3QFrhoGWAAQL4AAJhFrQEkCuECECQMcMbAARkQAARlscMaQqUKVt4BwQi0AXi/AAQxkAAsbscg");
            this.shape_10.setTransform(1530.8672,1074.5859,1.4531,1.4531);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.rf(["rgba(255,255,255,0.8)","rgba(255,255,255,0)"],[0,1],0,0,0,0,0,274.1).s().p("A9/d/QmTmSjGnnQjCnbAAorQAAxkMbsbQH8n8KCi3QFrhoGWAAQL4AAJhFrQEkCuECECQMcMbAARkQAARlscMaQqUKVt4BwQi0AXi/AAQxkAAsbscg");
            this.shape_11.setTransform(1530.8672,1074.5859,1.4531,1.4531);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.rf(["rgba(255,255,255,0.749)","rgba(255,255,255,0)"],[0,1],0,-1.9,0,0,-1.9,398.3).s().p("EgrlArSQohohkUqRQlOrVAAtdQAA5iSDyEQMMsLPrjwQJKiIJrA0QQMAaNCIAQHyFXFSH1QQQQRAAW+QAAZhyESDQvAPB0JCjQkHAgkVAAQ5hAAyEyEg");
            this.shape_12.setTransform(1406.775,966.6208);
        
            this.shape_13 = new cjs.Shape();
            this.shape_13.graphics.rf(["rgba(255,255,255,0.698)","rgba(255,255,255,0)"],[0,1],0,-2.8,0,0,-2.8,398.3).s().p("EgrlArJQn6n5kGpfQmDr3AAuVQAA5iSDyEQM2s1QxjVQKDh5KJBpQPIAzMPHxQI8GxEsJzQOcOdAAUbQAAZhyESDQvAPB0JCjQkHAgkVAAQ5hAAyEyEg");
            this.shape_13.setTransform(1282.675,857.693);
        
            this.shape_14 = new cjs.Shape();
            this.shape_14.graphics.rf(["rgba(255,255,255,0.651)","rgba(255,255,255,0)"],[0,1],0,-3.1,0,0,-3.1,398.3).s().p("EgrlArGQnTnSj5otQm3sYAAvNQAA5iSDyEQNftfR4i7QK8hqKmCeQOFBMLcHiQKEIMEHLwQMpMqAAR4QAAZhyESDQvAPA0JCjQkHAhkVAAQ5hAAyEyEg");
            this.shape_14.setTransform(1158.625,748.197);
        
            this.shape_15 = new cjs.Shape();
            this.shape_15.graphics.rf(["rgba(255,255,255,0.6)","rgba(255,255,255,0)"],[0,1],0,-3.1,0,0,-3.1,398.3).s().p("EgrlArFQmrmqjtn7Qnrs6AAwFQAA5iSDyEQOJuIS+igQL1hbLFDTQNABlKpHTQLOJmDhNuQK1K2AAPUQAAZhyESDQvAPB0JCjQkHAgkVAAQ5hAAyEyEg");
            this.shape_15.setTransform(1034.525,638.354);
        
            this.shape_16 = new cjs.Shape();
            this.shape_16.graphics.rf(["rgba(255,255,255,0.549)","rgba(255,255,255,0)"],[0,1],0,-2.9,0,0,-2.9,398.3).s().p("EgrlArIQmDmDjgnIQogtdAAw8QAA5iSDyEQOzuzUEiFQMvhMLiEIQL8B/J2HDQMXLAC7PsQJCJDAAMxQAAZhyESDQvAPB0JCiQkHAhkVAAQ5hAAyEyEg");
            this.shape_16.setTransform(910.425,528.2756);
        
            this.shape_17 = new cjs.Shape();
            this.shape_17.graphics.rf(["rgba(255,255,255,0.498)","rgba(255,255,255,0)"],[0,1],0,-2.5,0,0,-2.5,398.3).s().p("EgrlArMQlclcjTmVQpUt/AAx0QAA5iSDyEQPdvdVJhqQNpg9L/E9QK5CXJDG0QNgMbCWRqQHOHPAAKOQAAZhyESDQvAPA0JCjQkHAhkVAAQ5hAAyEyEg");
            this.shape_17.setTransform(786.325,418.0146);
        
            this.shape_18 = new cjs.Shape();
            this.shape_18.graphics.rf(["rgba(255,255,255,0.447)","rgba(255,255,255,0)"],[0,1],0,-2,0,0,-2,398.3).s().p("EgrlArRQk0k1jHliQqIuhAAysQAA5iSDyEQQHwGWPhQQOjguMdFyQJ0CxIQGkQOpN1BxToQFaFbAAHrQAAZhyESDQvAPB0JCiQkHAhkVAAQ5hAAyEyEg");
            this.shape_18.setTransform(662.225,307.6506);
        
            this.shape_19 = new cjs.Shape();
            this.shape_19.graphics.rf(["rgba(255,255,255,0.4)","rgba(255,255,255,0)"],[0,1],0,-1.3,0,0,-1.3,398.3).s().p("EgrlArXQkNkNi6kwQq8vDAAzkQAA5iSDyEQQwwwXWg1QPcgeM6GmQIxDKHdGWQPyPPBLVlQDnDoAAFHQAAZhyESDQvAPB0JCjQkHAgkVAAQ5hAAyEyEg");
            this.shape_19.setTransform(538.175,197.1972);
        
            this.shape_20 = new cjs.Shape();
            this.shape_20.graphics.rf(["rgba(255,255,255,0.349)","rgba(255,255,255,0)"],[0,1],0,-0.7,0,0,-0.7,398.3).s().p("EgrlAreQjmjmisj+QrxvkAA0cQAA5iSDyEQRaxaYcgbQQVgPNYHbQHtDkGqGGQQ7QqAmXjQBzB0AACkQAAZhyESDQvAPB0JCiQkHAhkVAAQ5hAAyEyEg");
            this.shape_20.setTransform(414.075,86.652);
        
            this.shape_21 = new cjs.Shape();
            this.shape_21.graphics.rf(["rgba(255,255,255,0.298)","rgba(255,255,255,0)"],[0,1],0,0,0,0,0,274.1).s().p("A9/eAQiDiEhtiLQorrGAAurQAAxjMbscQMcsbRjAAQL3AAJiFrQElCuECECQMbMbAARkIAAAAQAARlsbMbQqVKVt4BwQi0AWi/AAQxjAAscsbg");
            this.shape_21.setTransform(289.9711,-23.9039,1.4531,1.4531);
        
            var maskedShapeInstanceList = [this.shape_2,this.shape_3,this.shape_4,this.shape_5,this.shape_6,this.shape_7,this.shape_8,this.shape_9,this.shape_10,this.shape_11,this.shape_12,this.shape_13,this.shape_14,this.shape_15,this.shape_16,this.shape_17,this.shape_18,this.shape_19,this.shape_20,this.shape_21];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2}]}).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_4}]},1).to({state:[{t:this.shape_5}]},1).to({state:[{t:this.shape_6}]},1).to({state:[{t:this.shape_7}]},1).to({state:[{t:this.shape_8}]},1).to({state:[{t:this.shape_9}]},1).to({state:[{t:this.shape_10}]},1).to({state:[{t:this.shape_11}]},11).to({state:[{t:this.shape_12}]},1).to({state:[{t:this.shape_13}]},1).to({state:[{t:this.shape_14}]},1).to({state:[{t:this.shape_15}]},1).to({state:[{t:this.shape_16}]},1).to({state:[{t:this.shape_17}]},1).to({state:[{t:this.shape_18}]},1).to({state:[{t:this.shape_19}]},1).to({state:[{t:this.shape_20}]},1).to({state:[{t:this.shape_21}]},1).wait(11));
        
            // Arterie_rechts
            this.shape_22 = new cjs.Shape();
            this.shape_22.graphics.lf(["#7B7871","#8D8A84","#BBB9B5","#FFFFFF"],[0.169,0.278,0.502,0.796],71.7,79.6,-185.5,-206).s().p("A8AZPQjBjAAAkRIAArCQAAnJCymiQCqmUE5k4QE4k4GUirQGiixHIAAIQmAAQERAADADAQDBDBAAEQQAAEQjBDBQjADBkRAAIwmAAQmDgBkTETQkSERAAGFIAALCQAAERjBDAQjADBkRAAQkPAAjBjBg");
            this.shape_22.setTransform(858.275,270.9);
        
            this.shape_23 = new cjs.Shape();
            this.shape_23.graphics.lf(["#7B7871","#8D8A84","#BBB9B5","#FFFFFF"],[0.169,0.278,0.502,0.796],49.3,54.8,-127.7,-141.8).s().p("AzRRYQiFiFAAi7IAAnnQAAk5B6kgQB2kWDXjXQDWjXEXh1QEgh6E5AAILbAAQC7AACFCEQCFCFgBC7QABC7iFCFQiFCEi7AAIrbAAQkKAAi9C9Qi9C8AAELIAAHnQAAC7iFCFQiECEi7AAQi8AAiEiEg");
            this.shape_23.setTransform(858.2883,270.8988,1.4531,1.4531);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_22}]}).to({state:[{t:this.shape_23}]},39).wait(1));
        
            // Herz
            this.instance_1 = new lib.Symbol1();
            this.instance_1.setTransform(697.5,650.85,1.4531,1.4531);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).to({scaleX:1.5392,scaleY:1.5392},14).to({scaleX:1.4612,scaleY:1.4612},5).to({scaleX:1.552,scaleY:1.552},5).to({scaleX:1.4531,scaleY:1.4531},15).wait(1));
        
            // FlashAICB
            this.shape_24 = new cjs.Shape();
            this.shape_24.graphics.lf(["#8D8A84","#BBB9B5"],[0,0.82],15.4,42.8,-128,-100.6).s().p("A2tbRIAA/yQAAkoBykOQBvkEDJjKQDJjKEFhuQEOhzEnAAQEnAAEOBzQEFBuDJDKQDKDKBvEEQByEOAAEoIAAJ6I0hAAIAAp6QAAg6gqgpQgogqg7AAQg5AAgpAqQgpAoAAA7IAAfyg");
            this.shape_24.setTransform(691.8,174.45);
        
            this.shape_25 = new cjs.Shape();
            this.shape_25.graphics.lf(["#7B7871","#8D8A84","#BBB9B5","#FFFFFF"],[0.263,0.357,0.545,0.796],90.3,119.1,-114,-85.3).s().p("AvoSxIAA14QAAjLBPi6QBMi0CLiKQCKiLC0hMQC6hPDKAAQDLAAC6BPQC0BNCKCKQCLCKBMC0QBPC6AADLIAAG1IuIAAIAAm1QAAgogdgcQgcgdgoAAQgnAAgcAdQgcAcAAAoIAAV4g");
            this.shape_25.setTransform(691.7965,174.4477,1.4531,1.4531);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_24}]}).to({state:[{t:this.shape_25}]},39).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = p = new lib.AnMovieClip();
        p.nominalBounds = new cjs.Rectangle(932.5,600,227.5,522.5);
        // library properties:
        lib.properties = {
            id: 'DFDE106AEAC54924969250FB54434D32',
            width: 1395,
            height: 1200,
            fps: 24,
            color: "#FFFFFF",
            opacity: 0.00,
            manifest: [],
            preloads: []
        };
        
        
        
        // bootstrap callback support:
        
        (lib.Stage = function(canvas) {
            createjs.Stage.call(this, canvas);
        }).prototype = p = new createjs.Stage();
        
        p.setAutoPlay = function(autoPlay) {
            this.tickEnabled = autoPlay;
        }
        p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
        p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
        p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
        p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }
        
        p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }
        
        an.bootcompsLoaded = an.bootcompsLoaded || [];
        if(!an.bootstrapListeners) {
            an.bootstrapListeners=[];
        }
        
        an.bootstrapCallback=function(fnCallback) {
            an.bootstrapListeners.push(fnCallback);
            if(an.bootcompsLoaded.length > 0) {
                for(var i=0; i<an.bootcompsLoaded.length; ++i) {
                    fnCallback(an.bootcompsLoaded[i]);
                }
            }
        };
        
        an.compositions = an.compositions || {};
        an.compositions['DFDE106AEAC54924969250FB54434D32'] = {
            getStage: function() { return exportRoot.stage; },
            getLibrary: function() { return lib; },
            getSpriteSheet: function() { return ss; },
            getImages: function() { return img; }
        };
        
        an.compositionLoaded = function(id) {
            an.bootcompsLoaded.push(id);
            for(var j=0; j<an.bootstrapListeners.length; j++) {
                an.bootstrapListeners[j](id);
            }
        }
        
        an.getComposition = function(id) {
            return an.compositions[id];
        }
        
        
        an.makeResponsive = function(isResp, respDim, isScale, scaleType, domContainers) {		
            var lastW, lastH, lastS=1;		
            window.addEventListener('resize', resizeCanvas);		
            resizeCanvas();		
            function resizeCanvas() {			
                var w = lib.properties.width, h = lib.properties.height;			
                var iw = window.innerWidth, ih=window.innerHeight;			
                var pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;			
                if(isResp) {                
                    if((respDim=='width'&&lastW==iw) || (respDim=='height'&&lastH==ih)) {                    
                        sRatio = lastS;                
                    }				
                    else if(!isScale) {					
                        if(iw<w || ih<h)						
                            sRatio = Math.min(xRatio, yRatio);				
                    }				
                    else if(scaleType==1) {					
                        sRatio = Math.min(xRatio, yRatio);				
                    }				
                    else if(scaleType==2) {					
                        sRatio = Math.max(xRatio, yRatio);				
                    }			
                }
                domContainers[0].width = w * pRatio * sRatio;			
                domContainers[0].height = h * pRatio * sRatio;
                domContainers.forEach(function(container) {				
                    container.style.width = w * sRatio + 'px';				
                    container.style.height = h * sRatio + 'px';			
                });
                stage.scaleX = pRatio*sRatio;			
                stage.scaleY = pRatio*sRatio;
                lastW = iw; lastH = ih; lastS = sRatio;            
                stage.tickOnUpdate = false;            
                stage.update();            
                stage.tickOnUpdate = true;		
            }
        }
        an.handleSoundStreamOnTick = function(event) {
            if(!event.paused){
                var stageChild = stage.getChildAt(0);
                if(!stageChild.paused || stageChild.ignorePause){
                    stageChild.syncStreamSounds();
                }
            }
        }
        
        
        })(createjs = createjs||{}, AdobeAn = AdobeAn||{});
        var createjs, AdobeAn;

 }else{

    (function (cjs, an) {

        var p; // shortcut to reference prototypes
        var lib={};var ss={};var img={};
        lib.ssMetadata = [];
        
        
        (lib.AnMovieClip = function(){
            this.actionFrames = [];
            this.ignorePause = false;
            this.gotoAndPlay = function(positionOrLabel){
                cjs.MovieClip.prototype.gotoAndPlay.call(this,positionOrLabel);
            }
            this.play = function(){
                cjs.MovieClip.prototype.play.call(this);
            }
            this.gotoAndStop = function(positionOrLabel){
                cjs.MovieClip.prototype.gotoAndStop.call(this,positionOrLabel);
            }
            this.stop = function(){
                cjs.MovieClip.prototype.stop.call(this);
            }
        }).prototype = p = new cjs.MovieClip();
        // symbols:
        
        
        
        // stage content:
        (lib.auge = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Lidstrich_oben
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#F6F4F2").s().p("EA9jAKzQhahKithvQlajemli5Q1ApQ4hAAQ4jAA1vJQQmzC5lsDeQi2BvhgBKIjNAAQBrhWDKiCQGTkDHTjYQKNktKwitQNcjYNgAAQQWAAOHDYQLSCtJVEtQGsDYFBEDQChCCBLBWg");
            this.shape.setTransform(480.15,261.325);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#F6F4F2").s().p("EA4/AFRQloiOmlh1QiVgpiZglQ1WkgyhABIhCAAQxHAI1GD7QjYAvjSA5QmzB1l3COIkgB2IgBAAIjFgZQB8g2DIhTQFMiJGPh1IChgwQKNjBKthvQMNiCMbgJIBygBQQAABNyCKQLMBuJWDCIAsAOQGpCDEsCdQB1A5BNArQAKAZAEAWIh5AQIizACIkSh2g");
            this.shape_1.setTransform(477.4,287.65);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#F6F4F2").s().p("EA4TACoQl0g+mmgyQiXgRiagPQ3shsvLACIg/ABQuBAD35BYQjYAUjRAXQmzAxmBA9QinAgiGATIgBAAIi6gyQCKgVDIgkQFJg8GngxICigUQKMhVKrgxQL5g6MQgFIBuAAQPsgBNbA9QLHAxJXBUIAqAGQHCA3EoBEIDQApQgNAUgPASIhyAgIiwAGQh+gUifggg");
            this.shape_2.setTransform(476.05,314.0242);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#F6F4F2").s().p("EgmUAAnQtVglrKgkIiyhLQGWAdLPAcQWfA5YjAAQdvAAVZg6QKsgdEwgdIixBMQrMAjtjAlQ7FBKrvABIgJAAQq9AA6ghJg");
            this.shape_3.setTransform(475.325,341.4752);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[]}).to({state:[{t:this.shape}]},59).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[]},1).wait(5));
        
            // Lid
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.lf(["#F91C41","#005493"],[0,1],-420,0,420,0).s().p("EA+YAV0QhbhLiwhxQlfjhmoi8Q1RpX41gBQ41AB1QJXQmoC8lgDhQivBxhaBLIjRAAQBZivCwkGQFioLG3m0QJnphK6leQNqmzO6AAQO7AANpGzQK7FeJnJhQG3G0FhILQCxEGBZCvg");
            this.shape_4.setTransform(474.9,190.9);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.lf(["#F91C41","#005493"],[0,1],-395.4,-12.6,395.4,43.2).s().p("Eg/4AV1IhvgOQBZiuCxkGQFhoMG3mzQJnpiK7ldQNpm0O6AAQO7AANpG0QK7FdJnJiQG3GzFiIMQCwEGBZCuIhwAOIhXALQhEglhtg0IhUgmQlbiTmih6IhEgSQp8ipqvhTQr5hXs0ABIgXAAQs5ACr/BaQqoBVp2CnIgzAOQmiB5lbCTIhbAoQhqAzhBAjIhYgLg");
            this.shape_5.setTransform(475.05,192.15);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.lf(["#F91C41","#005493"],[0,1],-370.8,-25.2,370.9,86.5).s().p("EA79AVdIhSgTQlXhGmcg3IhEgIQp6g/qwgaQsGgRs9ABIgYAAQtFACsMATQqqAbp2A/IgyAFQmdA4lXBFIhYATQhnAdhCAPIhVgWIhpgbQBZivCwkFQFioMG3mzQJnpiK6ldQNqm0O6AAQO7AANqG0QK6FdJnJiQG3GzFiIMQCwEFBZCvIhrAbIhTAWQhEgQhpgfg");
            this.shape_6.setTransform(475.2,193.375);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.lf(["#F91C41","#005493"],[0,1],-346.2,-45,346.3,122.5).s().p("A55WvQqqgfp2gqIgygDIrqgUQguAAgqgBQhiAGhEgDIhQghIhkgqQBZiuCwkGQFioLG3mzQJnpjK6ldQNqm0O6AAQO7AANqG0QK6FdJnJjQG3GzFiILQCwEGBZCuIhlAqIhPAgQhFADhlgIIhQgBIroAUIhDADQp6ApqvAhQsUAztHADIgZAAIgwAAQs3AAsEgyg");
            this.shape_7.setTransform(475.3,201.8271);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.lf(["#F91C41","#005493"],[0,1],-321.6,-80,321.7,143.3).s().p("A6PZVQqthap1iTIgygLQmQhNlQhXQgtgKgpgLQhggQhDgXIhNgsIheg3QBZivCwkFQFioMG3mzQJnpiK6ldQNqm0O6AAQO7AANpG0QK7FdJnJiQG3GzFhIMQCxEFBZCvIhfA3IhLAsQhGAWhhAPQgmAJgoAIQlQBXmOBNIhCAOQp5CSqwBaQsgB5tSADIgZAAIgOAAQtVAAshh4g");
            this.shape_8.setTransform(475.45,225.4754);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.lf(["#F91C41","#005493"],[0,1],-297,-115,297.1,164.1).s().p("A6mb7QquiUp1j8IgygTQmJiQlNilQgsgUgpgVQhcgmhEgqIhJg3IhYhGQBZiuCxkGQFhoKG3m0QJnpjK7ldQNpm0O6AAQO7AANpG0QK7FdJnJjQG3G0FhIKQCxEGBZCuIhZBGIhIA2QhGAqhdAlQglATgoARQlLClmHCPIhBAZQp5D6qxCVQsrC+tdAEIgaAAIgJAAQtkAAsxi/g");
            this.shape_9.setTransform(475.6,249.0753);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.lf(["#F91C41","#005493"],[0,1],-272.4,-150,272.5,184.9).s().p("A68egQqwjOp1lkIgygcQmDjRlIjzQgrgfgpgfQhYg9hFg9IhFhCIhThTQBZivCwkFQFioLG3m0QJnpiK6ldQNqm0O6AAQO7AANqG0QK6FdJnJiQG3G0FiILQCwEFBZCvIhUBTIhEBBQhFA+haA8QgkAbgnAbQlHDymCDSIhAAjQp3FkqyDOQs4EEtnAEIgaAAIgDAAQtzAAtBkGg");
            this.shape_10.setTransform(475.75,272.7251);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.lf(["#F91C41","#005493"],[0,1],-247.8,-185,247.9,205.7).s().p("EgbSAhGQqxkIp2nNIgxglQl9kTlFlBQgqgpgogpQhUhThGhRIhBhMIhOhiQBZiuCxkEQFhoMG3m0QJnpjK7ldQNpm0O6AAQO7AANqG0QK6FdJnJjQG3G0FiIMQCwEEBZCuIhNBiIhABMQhIBRhVBSIhJBJQlDFAl7EUIg/AuQp3HMqyEJQtEFItzAGIgaAAIgDAAQt+AAtPlNg");
            this.shape_11.setTransform(475.85,296.35);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.lf(["#F91C41","#005493"],[0,1],-223.3,-220,223.3,226.6).s().p("EgbpAjsQqylDp2o2QnCmTl3nlQh2iXhfiNIhIhvQBZiuCwkFQFioMG3mzQJnpjK6ldQNqm0O6AAQO7AANqG0QK6FdJnJjQG3GzFiIMQCwEFBZCuIhIBvQhgCOh1CWQl3HlnCGTQp2I2qyFDQteGTuMAAQuKAAtfmTg");
            this.shape_12.setTransform(476,320);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[]}).to({state:[{t:this.shape_4}]},59).to({state:[{t:this.shape_5}]},1).to({state:[{t:this.shape_6}]},1).to({state:[{t:this.shape_7}]},1).to({state:[{t:this.shape_8}]},1).to({state:[{t:this.shape_9}]},1).to({state:[{t:this.shape_10}]},1).to({state:[{t:this.shape_11}]},1).to({state:[{t:this.shape_12}]},1).wait(1));
        
            // Glanz
            this.shape_13 = new cjs.Shape();
            this.shape_13.graphics.lf(["#FFFFFF","rgba(255,255,255,0)"],[0,1],-41.4,0,41.4,0).s().p("AkkEkQh5h5AAirQAAiqB5h6QB5h5CrAAQCrAAB5B5QB6B6AACqQAACrh6B5Qh5B6irAAQirAAh5h6g");
            this.shape_13.setTransform(555.65,283.15);
            this.shape_13._off = true;
        
            this.timeline.addTween(cjs.Tween.get(this.shape_13).wait(59).to({_off:false},0).wait(9));
        
            // Pupille
            this.shape_14 = new cjs.Shape();
            this.shape_14.graphics.f("#000000").s().p("AlZMxQifhDh6h7Qh7h7hEifQhFikAAi1QAAizBFilQBEifB7h7QB6h7CfhDQCmhGCzAAQC0AACmBGQCeBDB8B7QB6B7BECfQBFClAACzQAAC1hFCkQhECfh6B7Qh8B7ieBDQimBGi0AAQizAAimhGg");
            this.shape_14.setTransform(474.9,320.025);
        
            this.shape_15 = new cjs.Shape();
            this.shape_15.graphics.f("#000000").s().p("AlFMDQiWhAh0hzQhzh0hBiWQhBicAAiqQAAipBBicQBBiWBzh0QB0h0CWg/QCchCCpAAQCqAACcBCQCWA/B0B0QBzB0BBCWQBBCcAACpQAACqhBCcQhBCWhzB0Qh0BziWBAQicBCiqAAQipAAichCg");
            this.shape_15.setTransform(474.9,320.025);
        
            this.shape_16 = new cjs.Shape();
            this.shape_16.graphics.f("#000000").s().p("AkyLVQiMg8huhsQhthug7iNQg+iRAAihQAAifA+iSQA7iNBthtQBuhtCMg8QCTg+CfAAQCgAACSA+QCNA8BuBtQBtBtA7CNQA+CSAACfQAAChg+CRQg7CNhtBuQhuBsiNA8QiSA+igAAQifAAiTg+g");
            this.shape_16.setTransform(474.9,319.975);
        
            this.shape_17 = new cjs.Shape();
            this.shape_17.graphics.f("#000000").s().p("AlEMAQiVg/h0hzQhzh0hAiVQhBibAAipQAAipBBicQBAiVBzhzQB0h0CVg/QCbhBCpAAQCqAACbBBQCVA/B0B0QBzBzA/CVQBCCcAACpQAACphCCbQg/CVhzB0Qh0BziVA/QibBBiqAAQipAAibhBg");
            this.shape_17.setTransform(474.925,319.95);
        
            this.shape_18 = new cjs.Shape();
            this.shape_18.graphics.f("#000000").s().p("AlpNZQinhHiBiAQiBiBhHinQhJitAAi9QAAi8BJitQBHinCBiBQCBiBCnhHQCthIC8AAQC9AACtBIQCnBHCBCBQCBCBBHCnQBJCtAAC8QAAC9hJCtQhHCniBCBQiBCAinBHQitBJi9AAQi8AAithJg");
            this.shape_18.setTransform(474.925,319.975);
        
            this.shape_19 = new cjs.Shape();
            this.shape_19.graphics.f("#000000").s().p("AmPOzQi4hOiOiPQiPiOhOi4QhQi/AAjQQAAjRBQi/QBOi4CPiOQCOiOC4hPQC/hQDQAAQDRAAC/BQQC4BPCPCOQCOCOBOC4QBQC/AADRQAADQhQC/QhOC4iOCOQiPCPi4BOQi/BQjRAAQjQAAi/hQg");
            this.shape_19.setTransform(474.925,319.95);
        
            this.shape_20 = new cjs.Shape();
            this.shape_20.graphics.f("#000000").s().p("Am1QMQjJhWibibQicichWjKQhYjQAAjlQAAjkBYjRQBWjJCcicQCbibDJhWQDRhYDkAAQDlAADRBYQDKBWCcCbQCbCcBVDJQBYDRAADkQAADlhYDQQhVDKibCcQicCbjKBWQjRBYjlAAQjkAAjRhYg");
            this.shape_20.setTransform(474.925,319.975);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[]}).to({state:[{t:this.shape_14,p:{scaleX:1,scaleY:1,x:474.9,y:320.025}}]},59).to({state:[{t:this.shape_15}]},1).to({state:[{t:this.shape_16}]},1).to({state:[{t:this.shape_14,p:{scaleX:0.8309,scaleY:0.8309,x:474.8931,y:319.9702}}]},1).to({state:[{t:this.shape_17}]},1).to({state:[{t:this.shape_18}]},1).to({state:[{t:this.shape_19}]},1).to({state:[{t:this.shape_20}]},1).to({state:[{t:this.shape_14,p:{scaleX:1.3765,scaleY:1.3765,x:475.0014,y:320.0188}}]},1).wait(1));
        
            // Iris
            this.shape_21 = new cjs.Shape();
            this.shape_21.graphics.rf(["#FFFFFF","#5F5D59"],[0.384,1],0,0,0,0,0,208.7).s().p("AsreDQl3ifkhkgQkgkhifl3QikmDAAmpQAAmnCkmEQCfl3EgkhQEhkgF3ifQGDikGoAAQGpAAGDCkQF3CfEgEgQEiEhCeF3QCkGEAAGnQAAGpikGDQieF3kiEhQkgEgl3CfQmDCkmpAAQmoAAmDikg");
            this.shape_21.setTransform(474.9,320.025);
            this.shape_21._off = true;
        
            this.timeline.addTween(cjs.Tween.get(this.shape_21).wait(59).to({_off:false},0).wait(9));
        
            // Augenform
            this.shape_22 = new cjs.Shape();
            this.shape_22.graphics.f("#E4E1DD").s().p("A7JN4QqYlDpIo1QmhmSlQnlQhpiXhUiNIg/hvIDGAAIBABiQBUB+BpCHQFNGuGUFnQI1H1JxEfQMOFnM+AAQM/AAMOlnQJxkfI1n1QGUlnFNmuQBpiHBUh+IBAhiIDGAAIg+BvQhVCNhpCXQlQHkmhGTQpJI1qYFDQs+GTuLAAQuLAAs+mTg");
            this.shape_22.setTransform(474.9,459.575);
        
            this.shape_23 = new cjs.Shape();
            this.shape_23.graphics.rf(["#FFFFFF","#E5E1DC"],[0.365,1],0,0,0,0,0,399.9).s().p("A7BerQqXkfpKn2QmilnlUmuQhpiHhVh+IhAhiQBaiSCvjcQFgm4GolvQJUoCKSklQM2lvNpAAQNqAAM3FvQKREmJUIBQGoFvFfG4QCwDcBbCSIhABiQhWB+hqCHQlSGumjFnQpLH2qXEfQs8FnuFAAQuEAAs9lng");
            this.shape_23.setTransform(474.9,327.875);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[]}).to({state:[{t:this.shape_23},{t:this.shape_22}]},59).wait(9));
        
            // Lidstrich_oben
            this.shape_24 = new cjs.Shape();
            this.shape_24.graphics.f("#F6F4F2").s().p("EgmUAAnQtVglrKgkIiyhLQGWAdLPAcQWfA5YjAAQdvAAVZg6QKsgdEwgdIixBMQrMAjtjAlQ7FBKrvABIgJAAQq9AA6ghJg");
            this.shape_24.setTransform(475.325,341.4752);
        
            this.shape_25 = new cjs.Shape();
            this.shape_25.graphics.f("#F6F4F2").s().p("EA4TACoQl0g+mmgyQiXgRiagPQ3shsvLACIg/ABQuBAD35BYQjYAUjRAXQmzAxmBA9QinAgiGATIgBAAIi6gyQCKgVDIgkQFJg8GngxICigUQKMhVKrgxQL5g6MQgFIBuAAQPsgBNbA9QLHAxJXBUIAqAGQHCA3EoBEIDQApQgNAUgPASIhyAgIiwAGQh+gUifggg");
            this.shape_25.setTransform(476.05,314.0242);
        
            this.shape_26 = new cjs.Shape();
            this.shape_26.graphics.f("#F6F4F2").s().p("EA4/AFRQloiOmlh1QiVgpiZglQ1WkgyhABIhCAAQxHAI1GD7QjYAvjSA5QmzB1l3COIkgB2IgBAAIjFgZQB8g2DIhTQFMiJGPh1IChgwQKNjBKthvQMNiCMbgJIBygBQQAABNyCKQLMBuJWDCIAsAOQGpCDEsCdQB1A5BNArQAKAZAEAWIh5AQIizACIkSh2g");
            this.shape_26.setTransform(477.4,287.65);
        
            this.shape_27 = new cjs.Shape();
            this.shape_27.graphics.f("#F6F4F2").s().p("EA9jAKzQhahKithvQlajemli5Q1ApQ4hAAQ4jAA1vJQQmzC5lsDeQi2BvhgBKIjNAAQBrhWDKiCQGTkDHTjYQKNktKwitQNcjYNgAAQQWAAOHDYQLSCtJVEtQGsDYFBEDQChCCBLBWg");
            this.shape_27.setTransform(480.15,261.325);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[]}).to({state:[{t:this.shape_24}]},5).to({state:[{t:this.shape_25}]},1).to({state:[{t:this.shape_26}]},1).to({state:[{t:this.shape_27}]},1).to({state:[]},51).wait(9));
        
            // Lid
            this.shape_28 = new cjs.Shape();
            this.shape_28.graphics.lf(["#F91C41","#005493"],[0,1],-223.3,-220,223.3,226.6).s().p("EgbpAjsQqylDp2o2QnCmTl3nlQh2iXhfiNIhIhvQBZiuCwkFQFioMG3mzQJnpjK6ldQNqm0O6AAQO7AANqG0QK6FdJnJjQG3GzFiIMQCwEFBZCuIhIBvQhgCOh1CWQl3HlnCGTQp2I2qyFDQteGTuMAAQuKAAtfmTg");
            this.shape_28.setTransform(476,320);
        
            this.shape_29 = new cjs.Shape();
            this.shape_29.graphics.lf(["#F91C41","#005493"],[0,1],-247.8,-185,247.9,205.7).s().p("EgbSAhGQqxkIp2nNIgxglQl9kTlFlBQgqgpgogpQhUhThGhRIhBhMIhOhiQBZiuCxkEQFhoMG3m0QJnpjK7ldQNpm0O6AAQO7AANqG0QK6FdJnJjQG3G0FiIMQCwEEBZCuIhNBiIhABMQhIBRhVBSIhJBJQlDFAl7EUIg/AuQp3HMqyEJQtEFItzAGIgaAAIgDAAQt+AAtPlNg");
            this.shape_29.setTransform(475.85,296.35);
        
            this.shape_30 = new cjs.Shape();
            this.shape_30.graphics.lf(["#F91C41","#005493"],[0,1],-272.4,-150,272.5,184.9).s().p("A68egQqwjOp1lkIgygcQmDjRlIjzQgrgfgpgfQhYg9hFg9IhFhCIhThTQBZivCwkFQFioLG3m0QJnpiK6ldQNqm0O6AAQO7AANqG0QK6FdJnJiQG3G0FiILQCwEFBZCvIhUBTIhEBBQhFA+haA8QgkAbgnAbQlHDymCDSIhAAjQp3FkqyDOQs4EEtnAEIgaAAIgDAAQtzAAtBkGg");
            this.shape_30.setTransform(475.75,272.7251);
        
            this.shape_31 = new cjs.Shape();
            this.shape_31.graphics.lf(["#F91C41","#005493"],[0,1],-297,-115,297.1,164.1).s().p("A6mb7QquiUp1j8IgygTQmJiQlNilQgsgUgpgVQhcgmhEgqIhJg3IhYhGQBZiuCxkGQFhoKG3m0QJnpjK7ldQNpm0O6AAQO7AANpG0QK7FdJnJjQG3G0FhIKQCxEGBZCuIhZBGIhIA2QhGAqhdAlQglATgoARQlLClmHCPIhBAZQp5D6qxCVQsrC+tdAEIgaAAIgJAAQtkAAsxi/g");
            this.shape_31.setTransform(475.6,249.0753);
        
            this.shape_32 = new cjs.Shape();
            this.shape_32.graphics.lf(["#F91C41","#005493"],[0,1],-321.6,-80,321.7,143.3).s().p("A6PZVQqthap1iTIgygLQmQhNlQhXQgtgKgpgLQhggQhDgXIhNgsIheg3QBZivCwkFQFioMG3mzQJnpiK6ldQNqm0O6AAQO7AANpG0QK7FdJnJiQG3GzFhIMQCxEFBZCvIhfA3IhLAsQhGAWhhAPQgmAJgoAIQlQBXmOBNIhCAOQp5CSqwBaQsgB5tSADIgZAAIgOAAQtVAAshh4g");
            this.shape_32.setTransform(475.45,225.4754);
        
            this.shape_33 = new cjs.Shape();
            this.shape_33.graphics.lf(["#F91C41","#005493"],[0,1],-346.2,-45,346.3,122.5).s().p("A55WvQqqgfp2gqIgygDIrqgUQguAAgqgBQhiAGhEgDIhQghIhkgqQBZiuCwkGQFioLG3mzQJnpjK6ldQNqm0O6AAQO7AANqG0QK6FdJnJjQG3GzFiILQCwEGBZCuIhlAqIhPAgQhFADhlgIIhQgBIroAUIhDADQp6ApqvAhQsUAztHADIgZAAIgwAAQs3AAsEgyg");
            this.shape_33.setTransform(475.3,201.8271);
        
            this.shape_34 = new cjs.Shape();
            this.shape_34.graphics.lf(["#F91C41","#005493"],[0,1],-370.8,-25.2,370.9,86.5).s().p("EA79AVdIhSgTQlXhGmcg3IhEgIQp6g/qwgaQsGgRs9ABIgYAAQtFACsMATQqqAbp2A/IgyAFQmdA4lXBFIhYATQhnAdhCAPIhVgWIhpgbQBZivCwkFQFioMG3mzQJnpiK6ldQNqm0O6AAQO7AANqG0QK6FdJnJiQG3GzFiIMQCwEFBZCvIhrAbIhTAWQhEgQhpgfg");
            this.shape_34.setTransform(475.2,193.375);
        
            this.shape_35 = new cjs.Shape();
            this.shape_35.graphics.lf(["#F91C41","#005493"],[0,1],-395.4,-12.6,395.4,43.2).s().p("Eg/4AV1IhvgOQBZiuCxkGQFhoMG3mzQJnpiK7ldQNpm0O6AAQO7AANpG0QK7FdJnJiQG3GzFiIMQCwEGBZCuIhwAOIhXALQhEglhtg0IhUgmQlbiTmih6IhEgSQp8ipqvhTQr5hXs0ABIgXAAQs5ACr/BaQqoBVp2CnIgzAOQmiB5lbCTIhbAoQhqAzhBAjIhYgLg");
            this.shape_35.setTransform(475.05,192.15);
        
            this.shape_36 = new cjs.Shape();
            this.shape_36.graphics.lf(["#F91C41","#005493"],[0,1],-420,0,420,0).s().p("EA+YAV0QhbhLiwhxQlfjhmoi8Q1RpX41gBQ41AB1QJXQmoC8lgDhQivBxhaBLIjRAAQBZivCwkGQFioLG3m0QJnphK6leQNqmzO6AAQO7AANpGzQK7FeJnJhQG3G0FhILQCxEGBZCvg");
            this.shape_36.setTransform(474.9,190.9);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_28}]}).to({state:[{t:this.shape_29}]},1).to({state:[{t:this.shape_30}]},1).to({state:[{t:this.shape_31}]},1).to({state:[{t:this.shape_32}]},1).to({state:[{t:this.shape_33}]},1).to({state:[{t:this.shape_34}]},1).to({state:[{t:this.shape_35}]},1).to({state:[{t:this.shape_36}]},1).to({state:[]},51).wait(9));
        
            // Glanz
            this.shape_37 = new cjs.Shape();
            this.shape_37.graphics.lf(["#FFFFFF","rgba(255,255,255,0)"],[0,1],-41.4,0,41.4,0).s().p("AkkEkQh5h5AAirQAAiqB5h6QB5h5CrAAQCrAAB5B5QB6B6AACqQAACrh6B5Qh5B6irAAQirAAh5h6g");
            this.shape_37.setTransform(555.65,283.15);
        
            this.timeline.addTween(cjs.Tween.get(this.shape_37).wait(8).to({_off:true},51).wait(9));
        
            // Pupille
            this.shape_38 = new cjs.Shape();
            this.shape_38.graphics.f("#000000").s().p("AlZMxQifhDh6h7Qh7h7hEifQhFikAAi1QAAizBFilQBEifB7h7QB6h7CfhDQCmhGCzAAQC0AACmBGQCeBDB8B7QB6B7BECfQBFClAACzQAAC1hFCkQhECfh6B7Qh8B7ieBDQimBGi0AAQizAAimhGg");
            this.shape_38.setTransform(475.0014,320.0188,1.3765,1.3765);
        
            this.shape_39 = new cjs.Shape();
            this.shape_39.graphics.f("#000000").s().p("Am1QMQjJhWibibQicichWjKQhYjQAAjlQAAjkBYjRQBWjJCcicQCbibDJhWQDRhYDkAAQDlAADRBYQDKBWCcCbQCbCcBVDJQBYDRAADkQAADlhYDQQhVDKibCcQicCbjKBWQjRBYjlAAQjkAAjRhYg");
            this.shape_39.setTransform(474.925,319.975);
        
            this.shape_40 = new cjs.Shape();
            this.shape_40.graphics.f("#000000").s().p("AmPOzQi4hOiOiPQiPiOhOi4QhQi/AAjQQAAjRBQi/QBOi4CPiOQCOiOC4hPQC/hQDQAAQDRAAC/BQQC4BPCPCOQCOCOBOC4QBQC/AADRQAADQhQC/QhOC4iOCOQiPCPi4BOQi/BQjRAAQjQAAi/hQg");
            this.shape_40.setTransform(474.925,319.95);
        
            this.shape_41 = new cjs.Shape();
            this.shape_41.graphics.f("#000000").s().p("AlpNZQinhHiBiAQiBiBhHinQhJitAAi9QAAi8BJitQBHinCBiBQCBiBCnhHQCthIC8AAQC9AACtBIQCnBHCBCBQCBCBBHCnQBJCtAAC8QAAC9hJCtQhHCniBCBQiBCAinBHQitBJi9AAQi8AAithJg");
            this.shape_41.setTransform(474.925,319.975);
        
            this.shape_42 = new cjs.Shape();
            this.shape_42.graphics.f("#000000").s().p("AlEMAQiVg/h0hzQhzh0hAiVQhBibAAipQAAipBBicQBAiVBzhzQB0h0CVg/QCbhBCpAAQCqAACbBBQCVA/B0B0QBzBzA/CVQBCCcAACpQAACphCCbQg/CVhzB0Qh0BziVA/QibBBiqAAQipAAibhBg");
            this.shape_42.setTransform(474.925,319.95);
        
            this.shape_43 = new cjs.Shape();
            this.shape_43.graphics.f("#000000").s().p("AkyLVQiMg8huhsQhthug7iNQg+iRAAihQAAifA+iSQA7iNBthtQBuhtCMg8QCTg+CfAAQCgAACSA+QCNA8BuBtQBtBtA7CNQA+CSAACfQAAChg+CRQg7CNhtBuQhuBsiNA8QiSA+igAAQifAAiTg+g");
            this.shape_43.setTransform(474.9,319.975);
        
            this.shape_44 = new cjs.Shape();
            this.shape_44.graphics.f("#000000").s().p("AlFMDQiWhAh0hzQhzh0hBiWQhBicAAiqQAAipBBicQBBiWBzh0QB0h0CWg/QCchCCpAAQCqAACcBCQCWA/B0B0QBzB0BBCWQBBCcAACpQAACqhBCcQhBCWhzB0Qh0BziWBAQicBCiqAAQipAAichCg");
            this.shape_44.setTransform(474.9,320.025);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_38,p:{scaleX:1.3765,scaleY:1.3765,x:475.0014,y:320.0188}}]}).to({state:[{t:this.shape_39}]},1).to({state:[{t:this.shape_40}]},1).to({state:[{t:this.shape_41}]},1).to({state:[{t:this.shape_42}]},1).to({state:[{t:this.shape_38,p:{scaleX:0.8309,scaleY:0.8309,x:474.8931,y:319.9702}}]},1).to({state:[{t:this.shape_43}]},1).to({state:[{t:this.shape_44}]},1).to({state:[{t:this.shape_38,p:{scaleX:1,scaleY:1,x:474.9,y:320.025}}]},1).to({state:[]},51).wait(9));
        
            // Iris
            this.shape_45 = new cjs.Shape();
            this.shape_45.graphics.rf(["#FFFFFF","#5F5D59"],[0.384,1],0,0,0,0,0,208.7).s().p("AsreDQl3ifkhkgQkgkhifl3QikmDAAmpQAAmnCkmEQCfl3EgkhQEhkgF3ifQGDikGoAAQGpAAGDCkQF3CfEgEgQEiEhCeF3QCkGEAAGnQAAGpikGDQieF3kiEhQkgEgl3CfQmDCkmpAAQmoAAmDikg");
            this.shape_45.setTransform(474.9,320.025);
        
            this.timeline.addTween(cjs.Tween.get(this.shape_45).wait(8).to({_off:true},51).wait(9));
        
            // Augenform
            this.shape_46 = new cjs.Shape();
            this.shape_46.graphics.f("#E4E1DD").s().p("A7JN4QqYlDpIo1QmhmSlQnlQhpiXhUiNIg/hvIDGAAIBABiQBUB+BpCHQFNGuGUFnQI1H1JxEfQMOFnM+AAQM/AAMOlnQJxkfI1n1QGUlnFNmuQBpiHBUh+IBAhiIDGAAIg+BvQhVCNhpCXQlQHkmhGTQpJI1qYFDQs+GTuLAAQuLAAs+mTg");
            this.shape_46.setTransform(474.9,459.575);
        
            this.shape_47 = new cjs.Shape();
            this.shape_47.graphics.rf(["#FFFFFF","#E5E1DC"],[0.365,1],0,0,0,0,0,399.9).s().p("A7BerQqXkfpKn2QmilnlUmuQhpiHhVh+IhAhiQBaiSCvjcQFgm4GolvQJUoCKSklQM2lvNpAAQNqAAM3FvQKREmJUIBQGoFvFfG4QCwDcBbCSIhABiQhWB+hqCHQlSGumjFnQpLH2qXEfQs8FnuFAAQuEAAs9lng");
            this.shape_47.setTransform(474.9,327.875);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_47},{t:this.shape_46}]}).to({state:[]},59).wait(9));
        
            this._renderFirstFrame();
        
        }).prototype = p = new lib.AnMovieClip();
        p.nominalBounds = new cjs.Rectangle(534.9,371.3,370.20000000000005,217.40000000000003);
        // library properties:
        lib.properties = {
            id: 'B420F19D249F4430BB8874BB9C700CAA',
            width: 960,
            height: 640,
            fps: 24,
            color: "#FFFFFF",
            opacity: 0.00,
            manifest: [],
            preloads: []
        };
        
        
        
        // bootstrap callback support:
        
        (lib.Stage = function(canvas) {
            createjs.Stage.call(this, canvas);
        }).prototype = p = new createjs.Stage();
        
        p.setAutoPlay = function(autoPlay) {
            this.tickEnabled = autoPlay;
        }
        p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
        p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
        p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
        p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }
        
        p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }
        
        an.bootcompsLoaded = an.bootcompsLoaded || [];
        if(!an.bootstrapListeners) {
            an.bootstrapListeners=[];
        }
        
        an.bootstrapCallback=function(fnCallback) {
            an.bootstrapListeners.push(fnCallback);
            if(an.bootcompsLoaded.length > 0) {
                for(var i=0; i<an.bootcompsLoaded.length; ++i) {
                    fnCallback(an.bootcompsLoaded[i]);
                }
            }
        };
        
        an.compositions = an.compositions || {};
        an.compositions['B420F19D249F4430BB8874BB9C700CAA'] = {
            getStage: function() { return exportRoot.stage; },
            getLibrary: function() { return lib; },
            getSpriteSheet: function() { return ss; },
            getImages: function() { return img; }
        };
        
        an.compositionLoaded = function(id) {
            an.bootcompsLoaded.push(id);
            for(var j=0; j<an.bootstrapListeners.length; j++) {
                an.bootstrapListeners[j](id);
            }
        }
        
        an.getComposition = function(id) {
            return an.compositions[id];
        }
        
        
        an.makeResponsive = function(isResp, respDim, isScale, scaleType, domContainers) {		
            var lastW, lastH, lastS=1;		
            window.addEventListener('resize', resizeCanvas);		
            resizeCanvas();		
            function resizeCanvas() {			
                var w = lib.properties.width, h = lib.properties.height;			
                var iw = window.innerWidth, ih=window.innerHeight;			
                var pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;			
                if(isResp) {                
                    if((respDim=='width'&&lastW==iw) || (respDim=='height'&&lastH==ih)) {                    
                        sRatio = lastS;                
                    }				
                    else if(!isScale) {					
                        if(iw<w || ih<h)						
                            sRatio = Math.min(xRatio, yRatio);				
                    }				
                    else if(scaleType==1) {					
                        sRatio = Math.min(xRatio, yRatio);				
                    }				
                    else if(scaleType==2) {					
                        sRatio = Math.max(xRatio, yRatio);				
                    }			
                }
                domContainers[0].width = w * pRatio * sRatio;			
                domContainers[0].height = h * pRatio * sRatio;
                domContainers.forEach(function(container) {				
                    container.style.width = w * sRatio + 'px';				
                    container.style.height = h * sRatio + 'px';			
                });
                stage.scaleX = pRatio*sRatio;			
                stage.scaleY = pRatio*sRatio;
                lastW = iw; lastH = ih; lastS = sRatio;            
                stage.tickOnUpdate = false;            
                stage.update();            
                stage.tickOnUpdate = true;		
            }
        }
        an.handleSoundStreamOnTick = function(event) {
            if(!event.paused){
                var stageChild = stage.getChildAt(0);
                if(!stageChild.paused || stageChild.ignorePause){
                    stageChild.syncStreamSounds();
                }
            }
        }
        an.handleFilterCache = function(event) {
            if(!event.paused){
                var target = event.target;
                if(target){
                    if(target.filterCacheList){
                        for(var index = 0; index < target.filterCacheList.length ; index++){
                            var cacheInst = target.filterCacheList[index];
                            if((cacheInst.startFrame <= target.currentFrame) && (target.currentFrame <= cacheInst.endFrame)){
                                cacheInst.instance.cache(cacheInst.x, cacheInst.y, cacheInst.w, cacheInst.h);
                            }
                        }
                    }
                }
            }
        }
        
        
        })(createjs = createjs||{}, AdobeAn = AdobeAn||{});
        var createjs, AdobeAn;
 }
